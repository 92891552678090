import React, { useEffect, useState } from 'react';

import CloseIcon from '../../../assets/conversation/modal/CloseIcon';
import { useGetTags } from '../../../hooks/conversations/useGetTags';
import styles from './tag.module.scss';

type TagModalProps = {
    currentConversation: string | boolean | undefined;
    setIsTagModalOpen: any;
};

type Tag = {
    tag_name: string;
    id: number;
};

export const TagModal = ({ setIsTagModalOpen, currentConversation }: TagModalProps): JSX.Element => {
    const [tagInput, setTagInput] = useState<string>('');
    const [tags, setTags] = useState<Tag[] | null>(null);

    // const memoizedSetIsTagModalOpen = useCallback(() => setIsTagModalOpen((c: boolean) => !c), [setIsTagModalOpen]);

    const removeTag = (event: React.SyntheticEvent<HTMLSpanElement>) => {
        if (!(event.target instanceof HTMLSpanElement)) {
            return;
        }

        const element = event.target as HTMLElement;

        setTags((previousTags: Tag[] | null) => {
            if (previousTags) {
                return previousTags.filter((tag: Tag) => {
                    return tag.id.toString() !== element.dataset.index;
                });
            }
            return null;
        });
    };

    const fetchedTags = useGetTags(currentConversation);

    useEffect(() => {
        if (fetchedTags.data?.tags) {
            setTags(fetchedTags.data.tags);
        }
    }, [fetchedTags.data]);

    useEffect(() => {
        return () => setIsTagModalOpen(false);
    }, [currentConversation, setIsTagModalOpen]);

    return (
        <div className={styles.tagModal}>
            <CloseIcon onClick={() => setIsTagModalOpen((s: boolean) => !s)} />
            <form className={styles.tagInput}>
                <input
                    type="text"
                    placeholder="Type a tag"
                    required
                    name="message"
                    value={tagInput}
                    autoComplete="off"
                    onChange={e => {
                        setTagInput(e.target.value);
                    }}
                />
                <div className={styles.tagList}>
                    <ul>
                        {tags &&
                            tags.map((tag: Tag) => {
                                return (
                                    <li key={tag.id}>
                                        <span
                                            role="button"
                                            tabIndex={0}
                                            data-index={tag.id}
                                            onClick={e => removeTag(e)}
                                            onKeyDown={() => {
                                                return undefined;
                                            }}
                                        >
                                            x
                                        </span>
                                        {tag.tag_name}
                                    </li>
                                );
                            })}
                        {/* <li>
                            <span>x</span>
                            Tag 1
                        </li>
                        <li>
                            <span>x</span>
                            Tag 2
                        </li>
                        <li>
                            <span>x</span>
                            Tag 3
                        </li> */}
                    </ul>
                </div>
                <button className={styles.tagSubmitButton} type="submit">
                    Save changes
                </button>
            </form>
        </div>
    );
};
