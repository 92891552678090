import React, { useState } from 'react';

import AscendingIcon from '../../../assets/conversation/Ascending';
import FilterIcon from '../../../assets/conversation/Filter';
import MinimizeIcon from '../../../assets/conversation/Minimize';
import styles from '../conversation.module.scss';
import { ConversationSearch } from './Search';
import { SearchFilterToggler } from './SearchFilterToggler';

interface Conversation {
    userId: string;
    conversationId?: string;
    lastMessageTime?: string;
    lastMessageText?: string;
}

type ConversationControlsProps = {
    isConversationSelectorOpen: boolean;
    setIsConversationSelectorOpen: (value: React.SetStateAction<boolean>) => void;
    setFilteredConversations: (value: React.SetStateAction<Conversation[] | null>) => void;
    conversations: Conversation[] | null;
};

export const ConversationControls = ({
    isConversationSelectorOpen,
    setIsConversationSelectorOpen,
    setFilteredConversations,
    conversations,
}: ConversationControlsProps): JSX.Element => {
    const [isSearchMessages, setIsSearchMessages] = useState<boolean>(false);

    return (
        <div className="conversation-header-controls">
            <div
                className={`${styles.conversationFilterControls}`}
                style={!isConversationSelectorOpen ? { justifyContent: 'center' } : {}}
            >
                <div style={!isConversationSelectorOpen ? { display: 'none' } : {}}>
                    <button type="button" style={{ fontWeight: 400 }}>
                        Closed
                    </button>
                    <span> / </span>
                    <button type="button">Open</button>
                </div>
                <div style={!isConversationSelectorOpen ? { display: 'block' } : {}}>
                    <FilterIcon style={!isConversationSelectorOpen ? { display: 'none' } : {}} />
                    <AscendingIcon style={!isConversationSelectorOpen ? { display: 'none' } : {}} />
                    <MinimizeIcon
                        onClick={() => setIsConversationSelectorOpen(s => !s)}
                        marginLeft={!isConversationSelectorOpen ? '0px' : '15px'}
                    />
                </div>
            </div>
            <ConversationSearch
                isConversationSelectorOpen={isConversationSelectorOpen}
                isSearchMessages={isSearchMessages}
                conversations={conversations}
                setFilteredConversations={setFilteredConversations}
            />
            <SearchFilterToggler
                isConversationSelectorOpen={isConversationSelectorOpen}
                isSearchMessages={isSearchMessages}
                setIsSearchMessages={setIsSearchMessages}
            />
        </div>
    );
};
