import React from 'react';
import style from './team.module.scss';
import '../../../../styles/utils/_colors.scss';

const mockedTeamData = {
    Admin: [
        { avatar: null, name: 'Admin Name' },
        { avatar: null, name: 'Admin Name' },
    ],
    'Live Agent': [
        { avatar: null, name: 'Agent Name' },
        { avatar: null, name: 'Agent Name' },
    ],
    Analyst: [
        { avatar: null, name: 'Analyst Name' },
        { avatar: null, name: 'Analyst Name' },
    ],
};

const mockedSectionDescriptions = {
    Admin: {
        title: 'Admins',
        description: 'Section describing Admin user role and capabilities',
    },
    'Live Agent': {
        title: 'Live Agents',
        description:
            'Section describing Live Agent user role and capabilities. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    Analyst: {
        title: 'Analysts',
        description: 'Section describing Analyst user role and capabilities',
    },
};

const TeamSection = ({
    memberData,
    sectionMetaData,
}: {
    memberData: { avatar: string | null; name: string }[];
    sectionMetaData: { title: string; description: string };
}) => {
    return (
        <div className={style.memberSection}>
            <h2>{sectionMetaData.title}</h2>
            <span>{sectionMetaData.description}</span>
            {memberData.map((member, idx) => {
                return (
                    <div className={style.member} key={idx}>
                        {member.avatar ? (
                            <img src={member.avatar} alt="Member's Avatar" />
                        ) : (
                            <div className={style.blankAvatar} />
                        )}
                        <span>{member.name}</span>
                    </div>
                );
            })}
        </div>
    );
};

const EditIcon = () => {
    return (
        <div className={style.editMenu}>
            <span />
            <span />
            <span />
        </div>
    );
};

export const Team = () => {
    return (
        <div className={style.teamContent}>
            <div className={style.teamHeader}>
                <h1 className={style.title}>Team</h1>
                <h1 className={style.headerAction}>+ Add new</h1>
            </div>
            <div className={style.teamHeader}>
                <TeamSection
                    memberData={mockedTeamData['Admin']}
                    sectionMetaData={mockedSectionDescriptions['Admin']}
                />
                <span className={style.editLabel}>
                    <EditIcon />
                </span>
            </div>
            <div className={style.teamHeader}>
                <TeamSection
                    memberData={mockedTeamData['Live Agent']}
                    sectionMetaData={mockedSectionDescriptions['Live Agent']}
                />
                <span className={style.editLabel}>
                    <EditIcon />
                </span>
            </div>
            <div className={style.teamHeader}>
                <TeamSection
                    memberData={mockedTeamData['Analyst']}
                    sectionMetaData={mockedSectionDescriptions['Analyst']}
                />
                <span className={style.editLabel}>
                    <EditIcon />
                </span>
            </div>
            <button className={style.saveTeamButton}>Save Changes</button>
        </div>
    );
};
