import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={18} height={17} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0)">
                <rect x="-6" y="-18.1355" width="30" height="39.5845" fill="white" />
                <path
                    d="M0 14.8911C0 15.8469 0.85329 16.6216 1.89783 16.6216H16.1022C17.1504 16.6216 18 15.8435 18 14.8911V1.93901C18 0.983205 17.1467 0.208496 16.1022 0.208496H1.89783C0.849612 0.208496 0 0.986559 0 1.93901V14.8911ZM16.1022 15.7999H1.89783C1.34982 15.7999 0.901103 15.3908 0.901103 14.8911V12.7279L4.31426 9.61567L7.2309 12.2752C7.40744 12.4362 7.69064 12.4362 7.86718 12.2752L13.134 7.47265L17.0989 11.088V14.8911C17.0989 15.3908 16.6502 15.7999 16.1022 15.7999ZM1.89783 1.03016H16.1022C16.6502 1.03016 17.0989 1.43931 17.0989 1.93901V9.92422L13.4503 6.60068C13.2738 6.4397 12.9906 6.4397 12.8141 6.60068L7.5472 11.4032L4.63057 8.74371C4.45403 8.58273 4.17082 8.58273 3.99428 8.74371L0.901103 11.5642V1.93901C0.901103 1.43931 1.34982 1.03016 1.89783 1.03016Z"
                    fill="#7E7E7E"
                />
                <path
                    d="M5.57947 6.78518C6.84469 6.78518 7.87085 5.84614 7.87085 4.69581C7.87085 3.54549 6.84101 2.60645 5.57947 2.60645C4.31792 2.60645 3.28809 3.54549 3.28809 4.69581C3.28809 5.84614 4.31424 6.78518 5.57947 6.78518ZM5.57947 3.42811C6.34816 3.42811 6.96974 3.99824 6.96974 4.69581C6.96974 5.39339 6.34449 5.96352 5.57947 5.96352C4.81445 5.96352 4.18919 5.39339 4.18919 4.69581C4.18919 3.99824 4.81077 3.42811 5.57947 3.42811Z"
                    fill="#7E7E7E"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="18" height="16.4131" fill="white" transform="translate(0 0.208496)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgComponent;
