import React from 'react';
import { useStoreActions, useStoreState, Node, Edge } from 'react-flow-renderer';

import styles from './editnode.module.scss';

interface IMessageBlock {
    id: string;
    title: string;
    quickReplies: { title: string; type: string; link: string }[];
    textMessages: { text: string }[];
}
const EditMesageBlock = ({ messageBlockData }: { messageBlockData: IMessageBlock }) => {
    return (
        <div className={styles.editNodeBody}>
            <span>{messageBlockData.title}</span>
            {messageBlockData.textMessages.map(message => {
                return (
                    <>
                        <div className={styles.contentBox}>
                            {/* <input className={styles.messageContent} value={message.text} /> */}
                            <textarea className={styles.messageContent}>{message.text}</textarea>
                        </div>
                    </>
                );
            })}
            {messageBlockData.quickReplies.map(quickReply => {
                return <button className={styles.messageQuickReply}>{quickReply.title}</button>;
            })}
        </div>
    );
};

export const EditNode = ({ nodeData }: { nodeData: Edge<any> | Node<any> | null }) => {
    // const nodes = useStoreState((store) => store.nodes);
    // Check all existing Nodes inside Internal Store of React Flow Renderer
    // console.log(nodes);

    // Internal State Action Hook example to set new Elements inside Node
    // const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);
    console.log(nodeData);

    switch (nodeData?.type) {
        case 'messageBlock':
            return <EditMesageBlock messageBlockData={nodeData.data} />;
        case 'actionBlock':
            return <div>Edit for this type of Block not implemented yet.</div>;
        case 'conditionBlock':
            return <div>Edit for this type of Block not implemented yet.</div>;
        default:
            return <div>Edit for this type of Block not implemented yet.</div>;
    }
    // return <div className={styles.editNode}>{nodeData?.data && nodeData.data.title}</div>;
};
