import React, { useState } from 'react';
import style from './web-widgets.module.scss';

import { IWidgetSettings } from './WebWidgets';


const Counters = () => {
    const [count, setCount] = React.useState(0);
    return (
        <div className={style.textCounter}>
            <textarea
                name="WMessage"
                title="Welcome message"
                rows={5}
                cols={60}
                maxLength={200}
                onChange={e => setCount(e.target.value.length)}
            />
            <span className={style.counter}>{count}</span>
        </div>
    );
};

export const WidgetAdvancedSettings = ({
    widgetSettings,
    toggleAdvancedOpen,
}: {
    widgetSettings: IWidgetSettings;
    toggleAdvancedOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [chatbotState, setChatbotState] = useState(true);

    const handleChatbotToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let clickedState = (event.target as HTMLInputElement).innerText;
        clickedState === 'ON' ? setChatbotState(true) : setChatbotState(false);
    };

    return (
        <div className={style.AdvWidgetsContent}>
            <div className={style.titleRow}>
                <h1 className={style.title}>Advanced settings</h1>
                <span onClick={() => toggleAdvancedOpen(false)} className={style.closeModal}>
                    +
                </span>
            </div>
            <div className={style.advWidgetsInner}>
            <h2>Your domains</h2>
            <div className={style.domainList}>
                {widgetSettings.white_listed_domains.map((domain: string, index: number) => {
                    return (
                        <div key={index}>
                            <p>{domain}</p>
                            <span className={style.closeModal}>+</span>
                        </div>
                    );
                })}
            </div>

            <br />
            <h2>Assign messages</h2>
            <p>Starting message assign depending on a trigger type and URL</p>
            <br/>
            <button className={style.addNewWidget}>+ New widget</button>

            <div className={style.triggerCollapse}>
                <span>
                    <label>URL</label>
                    <input list="category" name="browser" id="browser" placeholder="category" />
                    <datalist id="category">
                        <option value="Welcome message" />
                        <option value="Question" />
                        <option value="Question 1" />
                    </datalist>
                </span>
                <span>
                    <label>Message</label>
                    <input list="category" name="browser" id="browser" placeholder="category" />
                    <datalist id="category">
                        <option value="Welcome message" />
                        <option value="Question" />
                        <option value="Question 1" />
                    </datalist>
                </span>
                <br />
                <h2>Conversation settings</h2>

                <span className={style.textBoxMessage}>
                    <label>Welcome message</label>
                    <Counters />
                </span>

                <br />

                <span>
                    <h2>Guest mode</h2>
                    <p className={style.question}>?</p>
                </span>
                <div className={style.widgetToggle}>
                    <button className={chatbotState ? style.on : style.off} onClick={handleChatbotToggle}>
                        ON
                    </button>
                    <button className={chatbotState ? style.off : style.on} onClick={handleChatbotToggle}>
                        OFF
                    </button>
                </div>
                <br />

                <span className={style.textBoxMessage}>
                    <label>Guest name</label>
                    <input name="WMessage" title="Welcome message" placeholder="Guest name" />
                </span>

                <br />

                <span>
                    <h2>Conversation closing setup</h2>
                    <p className={style.question}>?</p>
                </span>
                <span className={style.textBoxMessage}>
                    <label>Closing message</label>
                    <Counters />
                </span>

                <span>
                    <p>
                        <i>
                            Turn <b>Widget</b> on/off
                        </i>
                    </p>
                    <p className={style.question}>?</p>
                </span>
                <div className={style.widgetToggle}>
                    <button className={chatbotState ? style.on : style.off} onClick={handleChatbotToggle}>
                        ON
                    </button>
                    <button className={chatbotState ? style.off : style.on} onClick={handleChatbotToggle}>
                        OFF
                    </button>
                </div>
            </div>
            <span>
                <br/>
                <button className={style.saveWidgetsButton}>Save Changes</button>
            </span>
            </div>
        </div>
    );
};
