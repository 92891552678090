import React, { useState } from "react";
import style from "./chatbot-settings.module.scss";

export const ChatbotSettings = () => {
  const [chatbotState, setChatbotState] = useState(true);

  const handleChatbotToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let clickedState = (event.target as HTMLInputElement).innerText;
    clickedState === "ON" ? setChatbotState(true) : setChatbotState(false);
  };

  return (
    <div className={style.chatbotsContent}>
      <h1 className={style.title}>Chatbot settings</h1>
      <h2>Turn chatbot on/off</h2>
      <div className={style.chatbotToggle}>
        <button
          className={chatbotState ? style.on : style.off}
          onClick={handleChatbotToggle}
        >
          ON
        </button>
        <button
          className={chatbotState ? style.off : style.on}
          onClick={handleChatbotToggle}
        >
          OFF
        </button>
      </div>
    </div>
  );
};
