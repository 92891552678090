import React, { useEffect, useState } from 'react';
import style from './integrations.module.scss';

import MetaLogo from '../../../../assets/settings/integrations/FB.png';
import ViberLogo from '../../../../assets/settings/integrations/Viber.png';

// Extend Global Window type with FB object
declare global {
    interface Window {
        FB: any;
    }
}

interface IFBPageAccessToken {
    data: [
        {
            name: string;
            access_token: string;
            id: string;
        }
    ];
    paging: {
        cursors: {
            before: string;
            after: string;
        };
    };
}

export const Integrations = () => {
    const [fbPageAccessToken, setFbPageAccessToken] = useState<null | IFBPageAccessToken>(
        // JSON.parse(
        //     '{"data":[{"name":"ECommerce THNRM","access_token":"EAAC8RptZAkdABAKi6CRtGHKF5kfAPifmXR51eRCeGoMc6ZArMGWZAIcsyI85HZA4WSujJ3Q622wg3QNJ7sqNlUuglzDnnHpgTjgXuKd3tlMKBzFWf7QYYaF9D2OPtWO4TZAwXr7f9Km94ZChtdCCdu3AOaG8NZAKJmqUMP5eLzi5XZAsLUOFNHouMof1ksjZAh2KMhioeXA0ir0yWaf5cnKUF","id":"2291846307800366"}],"paging":{"cursors":{"before":"MjI5MTg0NjMwNzgwMDM2NgZDZD","after":"MjI5MTg0NjMwNzgwMDM2NgZDZD"}}}'
        // )
        null
    );

    const [fbSubscribedTo, setFbSubscribedTo] = useState<null | any>(null);

    const savePageAccessToken = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(fbPageAccessToken),
        };

        fetch('https://chatbotas.lt/api/integrations/setup', requestOptions)
            .then(response => response.json())
            .then(data => console.log('Saved Page Access Data. Data: ' + data))
            .catch(err => {
                console.log('Could not save Page Access Data. Error: ' + err);
                return false;
            });

        return true;
    };

    const retrievePageAccessToken = () => {
        window.FB.api(
            window.FB.getUserID() + '/accounts',
            'get',
            { fields: 'name,access_token', access_token: window.FB.getAccessToken() },
            function (response) {
                setFbPageAccessToken(response);
            }
        );
    };

    const onLoggedIn = () => {
        retrievePageAccessToken();
    };

    const onLoginClick = () => {
        (window as any).FB.login(
            function (response) {
                // handle the response
                console.log('FB Login Response: ');
                console.log(response);
                onLoggedIn();
            },
            {
                scope: 'email,pages_show_list,pages_manage_metadata,pages_messaging',
                return_scopes: true,
                enable_profile_selector: true,
            }
        );
    };

    useEffect(() => {
        if (!fbPageAccessToken) return;

        console.log('FB Page Access Token set: ');
        console.log(fbPageAccessToken);

        const subscribeToPageMessages = () => {
            if (fbPageAccessToken[0]) {
                window.FB.api(
                    '/' + fbPageAccessToken[0].id + '/subscribed_apps',
                    'POST',
                    {
                        subscribed_fields: 'messages,messaging_optins',
                        access_token: fbPageAccessToken[0].access_token,
                    },
                    function (response) {
                        if (response && !response.error) {
                            console.log('Subscribed to Messages for Page ID: ' + fbPageAccessToken[0].id);
                            console.log(response);
                        } else {
                            console.log('Subscribe to Messages Error: ');
                            console.log(response);
                        }
                    }
                );
            }
        };

        // subscribeToPageMessages();
    }, [fbPageAccessToken]);

    useEffect(() => {
        (window as any).fbAsyncInit = () => {
            (window as any).FB.init({
                appId: '207011440071120',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v11.0',
            });
        };
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode?.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, []);

    const onSubscribePageMessagingClick = pageId => {
        if (pageId === null) {
            setFbSubscribedTo(null);
            setFbPageAccessToken(null);
            return;
        }
        console.log('Subscribing to Page ID: ', pageId);
        setFbSubscribedTo(fbPageAccessToken);
    };

    return (
        <div className={style.integrationContent}>
            <h1 className={style.title}>Integrations</h1>
            <div className={style.section}>
                <div className={style.sectionRow}>
                    {fbPageAccessToken ? (
                        <div>
                            <h2>Select which page should subscribe to new Messages and Messaging Opt-Ins</h2>
                            <div
                                style={{
                                    display: 'flex',
                                    flexFlow: 'row',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    marginTop: '2.5em',
                                }}
                            >
                                <h3>
                                    Page ID: {fbPageAccessToken.data[0].id} - {fbPageAccessToken.data[0].name}
                                </h3>
                                <button
                                    style={
                                        fbSubscribedTo && {
                                            background: '#f35d5e',
                                            padding: '0 1em',
                                            width: '250px',
                                        }
                                    }
                                    onClick={() =>
                                        !fbSubscribedTo
                                            ? onSubscribePageMessagingClick(fbPageAccessToken.data[0].id)
                                            : onSubscribePageMessagingClick(null)
                                    }
                                >
                                    {fbSubscribedTo ? 'Unsubscribe Messaging' : 'Subscribe Messaging'}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <img
                                src={MetaLogo}
                                alt="Meta Messenger Logo"
                                style={{ width: '36px', height: '36px', marginRight: '10px' }}
                            />
                            <h2>Connect Facebook account</h2>
                            <button className={style.blue_hover} onClick={onLoginClick}>
                                Connect
                            </button>
                        </>
                    )}
                </div>
                <br />
            </div>
            <div className={style.section}>
                <div className={style.sectionRow}>
                    <img
                        src={ViberLogo}
                        alt="Viber Messenger Logo"
                        style={{ width: '36px', height: '36px', marginRight: '10px' }}
                    />
                    <h2>Connect Viber account</h2>
                </div>
                <div className={style.sectionRow}>
                    <div className={style.integrationInput}>
                        <label>API Key</label>
                        <input type="password" />
                    </div>
                    <button className={style.disengage}>Disconnect</button>
                </div>
            </div>
        </div>
    );
};
