import React from 'react';
import { ResponsiveContainer, AreaChart, Area, YAxis, CartesianGrid, PieChart, Pie, Cell, Label } from 'recharts';

const data = [
    {
        name: 'Page A',
        uv: 100,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 550,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

const pieData = [
    { name: 'Group A', value: 345 },
    { name: 'Group B', value: 350 },
];

export const PieVisualization = () => {
    return (
        <ResponsiveContainer>
            <PieChart>
                <defs>
                    <linearGradient id="fillGradient">
                        <stop offset="0%" stopColor="#3383F9" />
                        <stop offset="100%" stopColor="#27CFE6" />
                    </linearGradient>
                </defs>

                <Pie
                    dataKey="value"
                    data={pieData}
                    fill="#8884d8"
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={100}
                    cornerRadius={20}
                    paddingAngle={-20}
                    startAngle={-270}
                >
                    <Label
                        value="53.8"
                        position="center"
                        style={{
                            color: '#929292',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            fontSize: '36px',
                            lineHeight: '42px',
                        }}
                    />
                    {pieData.map((element, index) => {
                        if (index > 0) {
                            return <Cell key={`cell-${index}`} fill="url(#fillGradient)" stroke="0" />;
                        } else {
                            //
                            return (
                                <Cell
                                    key={`cell-${index}`}
                                    fill="#F0F0F0"
                                    stroke="0"
                                    style={{
                                        filter: `box-shadow(6px 10px 13px #E5ECF6;}`,
                                    }}
                                />
                            );
                        }
                    })}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export const Dashboard = () => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 40,
                    right: 30,
                    left: 0,
                    bottom: 30,
                }}
            >
                <CartesianGrid horizontal={false} />
                <defs>
                    <linearGradient id="strokeGradient">
                        <stop offset="0%" stopColor="#3383F9" stopOpacity={1} />
                        <stop offset="100%" stopColor="#27CFE6" stopOpacity={1} />
                    </linearGradient>
                </defs>
                <YAxis
                    style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        fontSize: '12px',
                        lineHeight: '14px',
                        textAlign: 'right',
                        color: '#000000',
                    }}
                />
                <Area type="monotone" dataKey="uv" stroke="url(#strokeGradient)" fill="#2cb4ee33" strokeWidth="5" />
            </AreaChart>
        </ResponsiveContainer>
    );
};
