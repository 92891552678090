import React, { useState } from 'react';
import style from './web-widgets.module.scss';
import { WidgetAdvancedSettings } from './WidgetAdvanced';
import '../../../../styles/utils/_colors.scss';

const WidgetController = ({ widgetName }: { widgetName: string }) => {
    const [chatbotState, setChatbotState] = useState<boolean>(true);

    const handleChatbotToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let clickedState = (event.target as HTMLInputElement).innerText;
        clickedState === 'ON' ? setChatbotState(true) : setChatbotState(false);
    };

    return (
        <div className={style.widgetControlSection}>
            <h2>
                Turn <b>Widget {widgetName}</b> on/off
            </h2>
            <div className={style.widgetToggle}>
                <button className={chatbotState ? style.on : style.off} onClick={handleChatbotToggle}>
                    ON
                </button>
                <button className={chatbotState ? style.off : style.on} onClick={handleChatbotToggle}>
                    OFF
                </button>
            </div>
        </div>
    );
};

const mockedWidgetsData = [
    { name: '1', is_on: true, white_listed_domains: ['https://botison.com', 'https://mockedwebsite.io'] },
    { name: '2', is_on: false, white_listed_domains: ['https://medboto.com', 'https://mockedclinic.io'] },
];

export interface IWidgetSettings {
    name: string;
    is_on: boolean;
    white_listed_domains: string[];
}

const WidgetSection = () => {
    const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);

    const toggleAdvancedOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsAdvancedOpen(currentState => !currentState);
    };
    return (
        <>
            {mockedWidgetsData.map((widget: IWidgetSettings, index: number) => {
                console.log(widget);
                return (
                    <React.Fragment key={index}>
                        <div className={style.widgetHeader}>
                            <h2>Widget {widget.name}</h2>
                            <button className={style.settingsButton} onClick={toggleAdvancedOpen}>
                                Advanced settings
                            </button>
                            {isAdvancedOpen && (
                                <WidgetAdvancedSettings
                                    toggleAdvancedOpen={setIsAdvancedOpen}
                                    widgetSettings={widget}
                                />
                            )}
                        </div>
                        <WidgetController widgetName={widget.name} />
                    </React.Fragment>
                );
            })}
        </>
    );
};

export const WebWidgets = () => {
    return (
        <div className={style.advFit}>
        <div className={style.webWidgetsContent}>
            <h1 className={style.title}>Web widgets</h1>
            <WidgetSection />
            <button className={style.addNewWidget}>+ New widget</button>
            <div className={style.widgetSettingGroup}>
                <span>Widget style</span>
                <button className={style.changeStyle}>Change</button>
            </div>
            <div className={style.widgetSettingGroup}>
                <span>Whitelisted domains</span>
                <input type="text" placeholder="Whitelisted domains" />
            </div>
            <button className={style.saveWidgetsButton}>Save Changes</button>
        </div>
        </div>
    );
};
