import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <div
            style={{
                display: 'flex',
                marginLeft: '15px',
            }}
        >
            <svg width={18} height={19} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M17.2245 1.43066e-06H0.775275C0.485154 -0.000563706 0.21918 0.166339 0.0865581 0.432141C-0.0478926 0.701521 -0.0242952 1.02591 0.147472 1.27193L6.17379 10.0142C6.1758 10.0172 6.178 10.02 6.18001 10.023C6.39897 10.3275 6.51733 10.6961 6.51806 11.0751V18.2026C6.51678 18.4138 6.59727 18.6165 6.74178 18.7662C6.88629 18.916 7.08275 19 7.28763 19C7.3919 19 7.49488 18.9785 7.5911 18.9375L10.9774 17.6077C11.2809 17.5124 11.4823 17.2176 11.4823 16.8625V11.0751C11.4829 10.6963 11.6014 10.3275 11.8202 10.023C11.8222 10.02 11.8244 10.0172 11.8264 10.0142L17.8525 1.27155C18.0243 1.02572 18.0479 0.701521 17.9134 0.432141C17.7808 0.166339 17.5148 -0.000563706 17.2245 1.43066e-06ZM11.0651 9.45168C10.7285 9.92206 10.5465 10.4908 10.5454 11.0751V16.7453L7.45464 17.9588V11.0751C7.45354 10.4908 7.27153 9.92206 6.93476 9.45168L1.08424 0.964497H16.9158L11.0651 9.45168Z"
                    fill="#353886"
                />
            </svg>
        </div>
    );
}

export default SvgComponent;
