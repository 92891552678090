import './styles/reactFlow.scss';
import React, { useCallback, useRef, useState } from 'react';
import ReactFlow, {
    addEdge,
    ArrowHeadType,
    Connection,
    Controls,
    Edge,
    Elements,
    FlowExportObject,
    Node,
    OnLoadParams,
    ReactFlowProvider,
    removeElements,
} from 'react-flow-renderer';
import { useHistory } from 'react-router-dom';

import { ActionNode } from './ActionNode';
import { AddNode } from './AddNode';
import ChevronLeft from './assets/ChevronLeft';
import styles from './builder.module.scss';
import { ConditionNode } from './ConditionNode';
import CustomEdge from './CustomEdge';
import { EditNode } from './EditNode';
// import MessageNode from './CustomNode';
import { MessageNode } from './MessageNode';
import mockedFlowData from './mock-flows-data/flows.json';

// This should load from API - if not found, generate only starting step
const initialElements = [
    {
        id: '1',
        type: 'input', // input node
        data: { label: 'Starting Step' },
        position: { x: 250, y: 25 },
    },
    {
        id: '2',
        type: 'messageBlock', // output node
        data: {
            title: 'Welcome Message',
            id: '1',
            textMessages: [
                {
                    text: 'Sveiki. Tai yra automatinis susirašinėjimų robotas. Įrašykite savo klausimą arba spauskite vieną iš mygtukų apačioje',
                },
            ],
            quickReplies: [
                { title: 'Paslaugos', type: 'Keyword', link: 'kw:Paslaugos' },
                { title: 'Gydytojai', type: 'Keyword', link: 'kw:Gydytojai' },
                { title: 'Kontaktai', type: 'Keyword', link: 'kw:Kontaktai' },
            ],
        },
        position: { x: 250, y: 78 },
    },
    {
        id: '3',
        type: 'messageBlock', // output node
        data: {
            title: 'Services',
            id: '2',
            textMessages: [{ text: 'Pasirinkite jus dominančią paslaugą' }],
            quickReplies: [
                { title: 'Skiepai', type: 'Keyword', link: 'kw:Skiepai' },
                { title: 'Konsultacijos', type: 'Keyword', link: 'kw:Konsultacijos' },
                { title: 'Procedūros', type: 'Keyword', link: 'kw:Procedūros' },
            ],
        },
        position: { x: 470, y: 750 },
    },
    {
        id: '4',
        type: 'messageBlock', // output node
        data: {
            title: 'Doctors',
            id: '2',
            textMessages: [{ text: 'Pasirinkite gydytojo tipą' }],
            quickReplies: [
                { title: 'Šeimos gydytojai', type: 'Keyword', link: 'kw:Skiepai' },
                { title: 'Odontologai', type: 'Keyword', link: 'kw:Konsultacijos' },
                { title: 'Dermatologai', type: 'Keyword', link: 'kw:Procedūros' },
            ],
        },
        position: { x: 750, y: 750 },
    },
    {
        id: '5',
        type: 'messageBlock', // output node
        data: {
            title: 'Contacts',
            id: '2',
            textMessages: [
                {
                    text: 'Susisiekite ir raskite mus lengviau!\nMūsų kontaktai:\nTelefonas: (8-684) 61657\nEl. paštas: info@chatmarketing.lt\nAdresas: Savanorių pr. 276 304-1, Kaunas 50201',
                },
            ],
            quickReplies: [
                { title: 'Darbo valandos', type: 'Keyword', link: 'kw:DarboValandos' },
                { title: 'Gydytojai', type: 'Keyword', link: 'kw:Gydytojai' },
                { title: 'Paslaugos', type: 'Keyword', link: 'kw:Paslaugos' },
            ],
        },
        position: { x: 1100, y: 750 },
    },
    {
        id: '6',
        type: 'messageBlock', // output node
        data: {
            title: 'Choose a Doctor',
            id: '2',
            textMessages: [
                {
                    text: 'Pasirinkite gydytoją:',
                },
            ],
            quickReplies: [
                { title: 'Gydytojas A', type: 'Keyword', link: 'kw:GydytojasA' },
                { title: 'Gydytojas B', type: 'Keyword', link: 'kw:GydytojasB' },
                { title: 'Gydytojas C', type: 'Keyword', link: 'kw:GydytojasC' },
            ],
        },
        position: { x: 900, y: 1200 },
    },
    {
        id: '7',
        type: 'messageBlock', // output node
        data: {
            title: 'Vaccination for Ticks',
            id: '2',
            textMessages: [
                {
                    text: 'Vakcinacija nuo erkinio encefalito viruso vakcinos (Ticovac 2,4 mkg 0,5 ml)Kaina: 34,99 EurVakcinacija yra efektyviausia priemonė nuo erkinio encefalito. Vakcinos nuo erkinio encefalito veiksmingumas siekia 98%.Standartinis skiepijimo planas: pirmoji dozė;antroji dozė (1-3 mėnesiai po pirmosios vakcinacijos); trečioji dozė (praėjus 5–12 mėnesių po antrosios dozės).',
                },
            ],
            quickReplies: [
                {
                    title: 'Daugiau informacijos',
                    type: 'Keyword',
                    link: 'kw:DaugiauInformacijos',
                },
                {
                    title: 'Registracija vakcinai',
                    type: 'Keyword',
                    link: 'kw:RegistracijaVakcinai',
                },
            ],
        },
        position: { x: 600, y: 1200 },
    },
    // animated edge
    {
        id: 'e1-2',
        source: '1',
        target: '2',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
        id: 'e2-3',
        source: '2',
        target: '3',
        sourceHandle: '0',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
        id: 'e2-4',
        source: '2',
        target: '4',
        sourceHandle: '1',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
        id: 'e3-5',
        source: '2',
        target: '5',
        sourceHandle: '2',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
        id: 'e3-7',
        source: '3',
        target: '7',
        sourceHandle: '0',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
        id: 'e5-4',
        source: '5',
        target: '4',
        sourceHandle: '1',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
        id: 'e5-5',
        source: '5',
        target: '3',
        sourceHandle: '2',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
        id: 'e4-6',
        source: '4',
        target: '6',
        sourceHandle: '0',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
        id: 'e4-7',
        source: '4',
        target: '6',
        sourceHandle: '1',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
        id: 'e4-8',
        source: '4',
        target: '6',
        sourceHandle: '2',
        animated: false,
        type: 'custom',
        arrowHeadType: ArrowHeadType.ArrowClosed,
    },
];

const FlowDataPreview = ({ data }: { data: string }) => {
    const previewStyle: React.CSSProperties = {
        position: 'absolute',
        display: 'block',
        top: '10%',
        right: 0,
        width: '40%',
        background: 'darkgrey',
        padding: '3em',
        zIndex: 20,
    };
    return (
        <div style={previewStyle}>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{data}</pre>
        </div>
    );
};

export const Builder = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isEditingNode, setIsEditingNode] = useState<boolean>(false);
    const [menuStyle, setMenuStyle] = useState<any>({});

    const [elements, setElements] = useState<Elements<any>>(initialElements);

    const [savedFlow, setSavedFlow] = useState<FlowExportObject<any> | null>(null);

    const [previewActive, setPreviewActive] = useState<null | string>(null);

    const [editNode, setEditNode] = useState<null | Node<any> | Edge<any>>(null);

    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams | null>(null);

    const history = useHistory();

    function handleHeaderClick() {
        history.push('/flows');
    }

    const onSave = useCallback(() => {
        if (reactFlowInstance) {
            const flow = reactFlowInstance.toObject();
            setSavedFlow(flow);
            console.log(JSON.stringify(flow));
        }
    }, [reactFlowInstance]);

    const onRestore = useCallback(() => {
        const restoreFlow = async () => {
            if (savedFlow) {
                // This does not work: can't render React Components from JSON data -
                // -- solution using a Custom Node, passing React.Node props as { data } props to CustomNode.tsx
                // -- when rendering, setting Node Type as a custom created type,
                // -- eg. : messageBlock, which refers to CustomNode React component import
                // ** this solves the problem of restoring data from JSON + we don't need customData inside node objects
                setElements(mockedFlowData.elements || []);
            }
        };

        restoreFlow();
    }, [setElements, savedFlow]);

    const onPreviewData = () => {
        if (previewActive) {
            setPreviewActive(null);
            return;
        }

        if (reactFlowInstance) {
            const flow = reactFlowInstance.toObject();
            setPreviewActive(JSON.stringify(flow));
        } else {
            console.log('No Flow detected to Preview the Data');
        }
    };

    const reactFlowInstanceOnLoad = (rfInstance: OnLoadParams | null) => {
        if (rfInstance) {
            setReactFlowInstance(rfInstance);
            rfInstance.fitView();
            rfInstance.zoomTo(1);
        }
        // onSave();
    };

    // gets called after end of edge gets dragged to another source or target
    // const onEdgeUpdate = (oldEdge, newConnection) => setElements(els => updateEdge(oldEdge, newConnection, els));
    const onConnect = (params: Edge<any> | Connection) => setElements(els => addEdge(params, els));

    const onElementClick = (event: React.MouseEvent<Element, MouseEvent>, element: Node<any> | Edge<any>) => {
        // console.log('click', element);
        setEditNode(element);
        setIsEditingNode(mode => !mode);
    };

    const onElementsRemove = (elementsToRemove: Elements<any>) =>
        setElements(els => removeElements(elementsToRemove, els));

    const setMenuCoordinates = (x: number, y: number) => {
        return { position: 'absolute', left: `${x}px`, top: `${y * 0.75}px` };
    };

    const onPaneClick = (event: React.MouseEvent<Element, MouseEvent>) => {
        if (isEditingNode) {
            setIsEditingNode(false);
            return;
        }
        if (isMenuOpen) {
            setIsMenuOpen(false);
            return;
        }

        console.log('onPaneClick');
        console.log(event);

        const newStyle = setMenuCoordinates(event.clientX, event.clientY - 95);
        setMenuStyle(newStyle);
        setIsMenuOpen(true);
    };

    const edgeTypes = {
        custom: CustomEdge,
    };

    const nodeTypes = {
        messageBlock: MessageNode,
        actionBlock: ActionNode,
        conditionBlock: ConditionNode,
    };

    return (
        <>
            <div className={styles.builderHeader}>
                <button type="submit" onClick={handleHeaderClick}>
                    Chatbot 1
                </button>
                <ChevronLeft />
                <span>Welcome Flow</span>
            </div>
            <div
                style={{
                    height: '100%',
                    position: 'relative',
                    background: 'white',
                    zIndex: 1,
                }}
                ref={reactFlowWrapper}
            >
                <ReactFlowProvider>
                    <ReactFlow
                        elements={elements}
                        onPaneClick={useCallback(() => onPaneClick, [onPaneClick])}
                        onLoad={useCallback(() => reactFlowInstanceOnLoad, [reactFlowInstanceOnLoad])}
                        onElementClick={useCallback(() => onElementClick, [onElementClick])}
                        onElementsRemove={useCallback(() => onElementsRemove, [onElementsRemove])}
                        onConnect={useCallback(() => onConnect, [onConnect])}
                        edgeTypes={edgeTypes}
                        nodeTypes={nodeTypes}
                        onConnectStart={useCallback(() => params => console.log('onConnectStart', params), [])}
                        onConnectStop={useCallback(() => params => console.log('onConnectStop', params), [])}
                        onEdgeDoubleClick={useCallback(() => params => console.log('onEdgeDoubleClick', params), [])}
                        onEdgeMouseMove={useCallback(() => params => console.log('onEdgeMouseMove', params), [])}
                    />
                    <Controls />
                    {isMenuOpen && (
                        <AddNode
                            style={menuStyle}
                            createElement={setElements}
                            reactFlowInstance={reactFlowInstance}
                            reactFlowWrapper={reactFlowWrapper}
                        />
                    )}
                    {isEditingNode && <EditNode nodeData={editNode} />}
                    <button type="button" onClick={onSave} className={styles.btn}>
                        Publish
                    </button>
                    <button type="button" onClick={onRestore} className={styles.btnRestore}>
                        Restore
                    </button>
                    <button type="button" onClick={onPreviewData} className={styles.btnRestore}>
                        Preview Flow Data
                    </button>
                </ReactFlowProvider>

                {previewActive && <FlowDataPreview data={previewActive} />}
            </div>
        </>
    );
};
