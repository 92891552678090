import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0)">
                <path
                    d="M15.478 0.208496H12.0217C11.803 0.208496 11.6257 0.390363 11.6257 0.614761V2.15857H9.92629C9.05175 2.15952 8.34306 2.88651 8.34213 3.78363V6.3615H7.13236C6.91361 6.3615 6.73632 6.54353 6.73632 6.76777V8.00878H4.36735V6.46497C4.36735 6.24058 4.19006 6.05871 3.97131 6.05871H0.514936C0.296186 6.05871 0.118896 6.24058 0.118896 6.46497V10.3651C0.118896 10.5895 0.296186 10.7714 0.514936 10.7714H3.97131C4.19006 10.7714 4.36735 10.5895 4.36735 10.3651V8.82131H6.73632V10.0623C6.73632 10.2866 6.91361 10.4686 7.13236 10.4686H8.34213V13.0465C8.34306 13.9436 9.05175 14.6706 9.92629 14.6715H11.6257V16.2153C11.6257 16.4397 11.803 16.6216 12.0217 16.6216H15.478C15.6967 16.6216 15.874 16.4397 15.874 16.2153V12.3152C15.874 12.0908 15.6967 11.9089 15.478 11.9089H12.0217C11.803 11.9089 11.6257 12.0908 11.6257 12.3152V13.859H9.92629C9.48895 13.8585 9.13468 13.4949 9.13421 13.0465V10.4686H10.171C10.3898 10.4686 10.5671 10.2866 10.5671 10.0623V6.76777C10.5671 6.54337 10.3898 6.3615 10.171 6.3615H9.13421V3.78363C9.13468 3.33515 9.48895 2.97157 9.92629 2.9711H11.6257V4.5149C11.6257 4.7393 11.803 4.92117 12.0217 4.92117H15.478C15.6967 4.92117 15.874 4.7393 15.874 4.5149V0.614761C15.874 0.390363 15.6967 0.208496 15.478 0.208496ZM3.57527 9.95885H0.910976V6.87124H3.57527V9.95885ZM12.4178 12.7214H15.0819V15.8091H12.4178V12.7214ZM9.77499 9.65606H7.5284V7.17403H9.77499V9.65606ZM15.0819 4.10864H12.4178V1.02103H15.0819V4.10864Z"
                    fill="#7E7E7E"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16.4131" fill="white" transform="translate(0 0.208496)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgComponent;
