import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={22} height={23} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.3077 12.3847C11.7869 12.3847 12.2017 12.2099 12.5517 11.8595C12.9017 11.5095 13.0771 11.0946 13.0771 10.6156V1.76933C13.0771 1.29005 12.9019 0.875441 12.5517 0.525468C12.2018 0.175108 11.7869 0 11.3077 0C10.8284 0 10.4138 0.175301 10.0636 0.525468C9.71344 0.875441 9.53833 1.29024 9.53833 1.76933V10.6156C9.53833 11.0947 9.71344 11.5095 10.0636 11.8595C10.4138 12.2099 10.8285 12.3847 11.3077 12.3847Z"
                fill="#353886"
            />
            <path
                d="M20.8107 7.64374C20.0688 6.16029 19.0253 4.91623 17.68 3.91192C17.293 3.61709 16.8553 3.50186 16.367 3.5663C15.8785 3.63084 15.4915 3.86118 15.2059 4.25729C14.911 4.64425 14.7983 5.07962 14.8673 5.56341C14.9362 6.04744 15.1645 6.43662 15.5515 6.7315C16.4541 7.41334 17.1526 8.24739 17.6453 9.23336C18.1384 10.2192 18.3846 11.2698 18.3846 12.3846C18.3846 13.3432 18.198 14.2575 17.8249 15.1285C17.4518 15.9993 16.9472 16.7524 16.3113 17.3883C15.6754 18.024 14.9224 18.5285 14.0515 18.9017C13.1807 19.275 12.2661 19.4619 11.3078 19.4619C10.3496 19.4619 9.43503 19.275 8.56424 18.9017C7.69335 18.5285 6.94015 18.024 6.30425 17.3883C5.66864 16.7524 5.16409 15.9993 4.79083 15.1285C4.41767 14.2575 4.23104 13.3432 4.23104 12.3846C4.23104 11.2698 4.47755 10.2194 4.97049 9.23336C5.46347 8.24749 6.16148 7.41349 7.06447 6.7315C7.45148 6.43643 7.67945 6.04729 7.74868 5.56341C7.81777 5.07982 7.70497 4.64425 7.41004 4.25729C7.12436 3.86118 6.73982 3.63079 6.25593 3.5663C5.7721 3.50182 5.33198 3.61709 4.93583 3.91192C3.59035 4.91623 2.54696 6.16029 1.80504 7.64374C1.06317 9.12738 0.692383 10.7077 0.692383 12.3846C0.692383 13.8223 0.973465 15.1952 1.53548 16.5036C2.0977 17.8121 2.85317 18.9407 3.80244 19.89C4.75142 20.839 5.88025 21.5948 7.18884 22.1564C8.49733 22.7187 9.87031 23.0001 11.3078 23.0001C12.7453 23.0001 14.1182 22.7187 15.4267 22.1564C16.7354 21.5946 17.8642 20.839 18.8133 19.89C19.7623 18.941 20.5177 17.8121 21.0799 16.5036C21.642 15.1952 21.9228 13.822 21.9228 12.3846C21.9229 10.7076 21.5526 9.12734 20.8107 7.64374Z"
                fill="#353886"
            />
        </svg>
    );
}

export default SvgComponent;
