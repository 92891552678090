import gql from 'graphql-tag';

import { useSubscription } from '@apollo/client';

export const subscription = gql`
    subscription conversationCreated {
        conversationCreated {
            id
            uuid
            client_id
            is_connected
            current_flow
            current_flow_step
            conversation_status
            mod_time
            last_text
            last_message_time
        }
    }
`;

export default () => useSubscription(subscription);
