import React, { MouseEvent } from 'react';
import { Handle, Position } from 'react-flow-renderer';

import { ReactComponent as ClockIcon } from './assets/clock.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import styles from './builder.module.scss';

type MessageBlockProps = {
    data: {
        id: string;
        title: string;
        textMessages?: [
            {
                text: string;
            }
        ];
        quickReplies?: [{ title: string; type: string; link: string }];
    };
};

export const MessageNode = ({ data }: MessageBlockProps) => {
    const handleQuickReplyClick = (event: MouseEvent<HTMLButtonElement> | undefined) => {
        console.log(event);
    };

    return (
        <div>
            <Handle type="source" position={Position.Top} id="z" />
            <h3 className={styles.nodeHeader}>
                <span>{data.title}</span>
                <CloseIcon />
            </h3>

            <div className={styles.nodeContentWrapper}>
                {data.textMessages &&
                    data.textMessages.map((txt, idx) => (
                        <h5 key={idx} className={styles.textBlock}>
                            {txt.text}
                        </h5>
                    ))}
                {data.quickReplies &&
                    data.quickReplies.map((qr, idx) => (
                        <button type="button" className={styles.quickReply} onClick={handleQuickReplyClick} key={idx}>
                            <ClockIcon />
                            <span>{qr.title}</span>
                            <Handle type="source" position={Position.Right} id={idx.toString()} />
                        </button>
                    ))}
            </div>
        </div>
    );
};
