import { useState } from 'react';
import style from './account.module.scss';
import AccountPlaceholder from '../../../../assets/settings/integrations/accountPlaceholder.svg';
import '../../../../styles/utils/_colors.scss';

const VisibilityIcon = ({ isHidden = true }: { isHidden?: boolean }) => {
    return (
        <div className={style.visibilityEyeShape}>
            <div className={style.visibilityEyeBall} />
            {isHidden && <div className={style.visibilityHide} />}
        </div>
    );
};

export const Account = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(passwordShown => !passwordShown);
    };
    return (
        <div className={style.accountContent}>
            <h1 className={style.title}>Account</h1>
            {/*test
            <span className={style.blue}></span>*/}
            <div className={style.accountRow}>
                <div>
                    <span className={style.placeholder}>
                        <img className={style.editAction} src={AccountPlaceholder} alt="Account" />
                    </span>
                    <button type="submit">Change picture</button>
                </div>
                <div>
                    <div className={style.widgetSettingGroup}>
                        <h2>Lorem Ipsum</h2>
                        <span>
                            <label>Full name:</label>
                            <input name="fname" type="text" placeholder="Full name" title="Full name" />
                        </span>
                        <span>
                            <label>User name:</label>
                            <input name="usern" type="text" placeholder="User name" title="User name" />
                        </span>
                    </div>
                </div>
            </div>
            <hr />
            <div className={style.widgetSettingGroup}>
                <h2>Personal information</h2>
                <span>
                    <label>Contact number:</label>
                    <input name="contactn" type="text" placeholder="Contact number" title="Contact number" />
                </span>
                <span>
                    <label>Your email:</label>
                    <input name="email" type="text" placeholder="Email address" title="Email address" />
                </span>
                <br />
                <h2>Change your password</h2>
                <span>
                    <label>New password:</label>
                    <input
                        name="password"
                        type={passwordShown ? 'text' : 'password'}
                        placeholder="Password"
                        title="Password"
                    />
                    <button className={style.showPass} onClick={togglePassword}>
                        <VisibilityIcon isHidden={passwordShown} />
                    </button>
                </span>
                <span>
                    <label>Repeat password:</label>
                    <input
                        name="rpassword"
                        type={passwordShown ? 'text' : 'password'}
                        placeholder="Repeat password"
                        title="Password"
                    />
                    <button className={style.showPass} onClick={togglePassword}>
                        <VisibilityIcon isHidden={passwordShown} />
                    </button>
                </span>
                <br />
                <h2>Confirm changes</h2>
                <span>
                    <label>Your password:</label>
                    <input name="password" type="password" placeholder="Password" title="Password" />
                </span>
                <br/>
                <button className='saveWidgetsButton blue_buttons'>Save Changes</button>
            </div>
        </div>
    );
};
