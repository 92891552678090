import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16.6667 1.69116H0V16.1733H20V1.69116H16.6667ZM2.66667 15.594H0.666667V13.8562H2.66667V15.594ZM2.66667 13.2769H0.666667V11.539H2.66667V13.2769ZM2.66667 10.9597H0.666667V9.22188H2.66667V10.9597ZM2.66667 8.64259H0.666667V6.90473H2.66667V8.64259ZM2.66667 6.32545H0.666667V4.58759H2.66667V6.32545ZM2.66667 4.00831H0.666667V2.27045H2.66667V4.00831ZM16.6667 15.594H3.33333V2.27045H16.6667V15.594ZM19.3333 15.594H17.3333V13.8562H19.3333V15.594ZM19.3333 13.2769H17.3333V11.539H19.3333V13.2769ZM19.3333 10.9597H17.3333V9.22188H19.3333V10.9597ZM19.3333 8.64259H17.3333V6.90473H19.3333V8.64259ZM19.3333 6.32545H17.3333V4.58759H19.3333V6.32545ZM19.3333 4.00831H17.3333V2.27045H19.3333V4.00831Z"
                fill="#7E7E7E"
            />
            <path
                d="M12.6237 8.78753C12.5942 8.74271 12.5515 8.7056 12.4999 8.67997L7.83326 6.36282C7.67384 6.28285 7.46996 6.33031 7.37792 6.46883C7.34838 6.51329 7.33296 6.56376 7.33326 6.6151V11.2494C7.33338 11.3528 7.39688 11.4482 7.49992 11.4999C7.60305 11.5517 7.73013 11.5517 7.83326 11.4999L12.4999 9.18279C12.6597 9.10335 12.7151 8.92642 12.6237 8.78753ZM7.99992 10.75V7.11445L11.6613 8.93225L7.99992 10.75Z"
                fill="#7E7E7E"
            />
        </svg>
    );
}

export default SvgComponent;
