import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={18} height={3} viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width={18} height={3} rx={1.5} fill="#fff" />
        </svg>
    );
}

export default SvgComponent;
