import React from 'react';
import styles from './overview.module.scss';
import { Dashboard, PieVisualization } from './Dashboard';
import { IntentsTable } from './IntentsTable';

export const Overview = () => {
    return (
        <div className={styles.overviewContainer}>
            <h1>Dashboard</h1>
            <div className={styles.statisticsVisualization}>
                <div className={styles.overviewPie}>
                    <h2>Closed conversations (%)</h2>
                    <div className={styles.pieRing}>
                        <PieVisualization />
                    </div>
                </div>
                <div className={styles.overviewBody}>
                    <h2>Daily conversations</h2>
                    <Dashboard />
                </div>
            </div>
            <div className={styles.overviewTable}>
                <IntentsTable />
            </div>
        </div>
    );
};
