import React from 'react';

export const ConditionNode = () => {
    return (
        <div className="conditionNode">
            <h3 className="conditionHeader">Condition block example</h3>
            <h5>Add condition</h5>
        </div>
    );
};
