import React, { useMemo, useState } from 'react';

import style from './billing.module.scss';

import { loadStripe } from '@stripe/stripe-js';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    Elements,
} from '@stripe/react-stripe-js';

import StatusMessages, { useMessages } from './utils/StatusMessages';

const useOptions = () => {
    const options = useMemo(
        () => ({
            showIcon: true,
            style: {
                base: {
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontWeight: '500',
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        []
    );

    return options;
};

const stripePromise = loadStripe(
    'pk_test_51KmT4SLhJLkRnAmMXuwpNSCxvc8TlIcXyszdEAbrhyMO5CrY32JYg3kEONnQPD45VejWkARp1ivzVjtXk8YaR4WI00LV8Vtalb'
);

const SplitForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    // Development utility to display Payment messages on screen. Remove later.
    const [messages, addMessage] = useMessages();

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
        event.preventDefault();

        setIsProcessingPayment(true);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        if (!cardElement) return;

        // const payload = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: cardElement,
        // });

        const { error: backendError, clientSecret } = await fetch('/api/create-payment-intent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paymentMethodType: 'card',
                currency: 'eur',
            }),
        }).then(r => r.json());

        if (backendError) {
            addMessage('Backend Error: ' + backendError.message);
            return;
        }

        addMessage('Client secret returned');

        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: 'Test User',
                },
            },
        });

        if (stripeError) {
            // Show error to your customer (e.g., insufficient funds)
            addMessage('Stripe Error (confirm  card payment): ' + stripeError.message);
            return;
        }

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.

        addMessage(`Payment ${paymentIntent?.status}: ${paymentIntent?.id}`);
    };

    return (
        <>
            <form onSubmit={handleSubmit} className={style.cardForm}>
                <h2>Connect</h2>
                <label>
                    <span>Card number</span>
                    <CardNumberElement
                        className={style.cardNumberElement}
                        options={options}
                        onReady={() => {
                            // console.log('CardNumberElement [ready]');
                        }}
                        onChange={event => {
                            console.log('CardNumberElement [change]', event);
                        }}
                        onBlur={() => {
                            console.log('CardNumberElement [blur]');
                        }}
                        onFocus={() => {
                            console.log('CardNumberElement [focus]');
                        }}
                    />
                </label>
                <span className={style.formRow}>
                    <label>
                        <span>Expiration date</span>
                        <CardExpiryElement
                            className={style.cardExpiryElement}
                            options={options}
                            onReady={() => {
                                // console.log('CardNumberElement [ready]');
                            }}
                            onChange={event => {
                                console.log('CardNumberElement [change]', event);
                            }}
                            onBlur={() => {
                                console.log('CardNumberElement [blur]');
                            }}
                            onFocus={() => {
                                console.log('CardNumberElement [focus]');
                            }}
                        />
                    </label>
                    <label>
                        <span>CVC</span>
                        <CardCvcElement
                            className={style.cardCvcElement}
                            options={options}
                            onReady={() => {
                                // console.log('CardNumberElement [ready]');
                            }}
                            onChange={event => {
                                console.log('CardNumberElement [change]', event);
                            }}
                            onBlur={() => {
                                console.log('CardNumberElement [blur]');
                            }}
                            onFocus={() => {
                                console.log('CardNumberElement [focus]');
                            }}
                        />
                    </label>
                </span>

                <button type="submit" disabled={!stripe || isProcessingPayment} className={style.payButton}>
                    Pay
                </button>
            </form>
            <StatusMessages messages={messages} />
        </>
    );
};

export const Stripe = () => (
    <Elements stripe={stripePromise}>
        <SplitForm />
    </Elements>
);

/*
 * Additional example of using Payment Element Form instead of individual Elements to construct payment form.
 *
 */

// const PaymentElementForm = () => {
//     const stripe = useStripe();
//     const elements = useElements();

//     const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
//         event.preventDefault();

//         if (!stripe || !elements) {
//             // Stripe.js has not loaded yet. Make sure to disable
//             // form submission until Stripe.js has loaded.
//             return;
//         }

//         const cardElement = elements.getElement(CardNumberElement);

//         if (!cardElement) return;

//         const payload = await stripe.createPaymentMethod({
//             type: 'card',
//             card: cardElement,
//         });

//         console.log('[PaymentMethod]', payload);
//     };

//     return (
//         <form onSubmit={handleSubmit}>
//             <PaymentElement />
//         </form>
//     );
// };

// const options = {
//     // passing the client secret obtained from the server
//     clientSecret: '{{CLIENT_SECRET}}',
// };
