import React, { MouseEvent, ReactElement, useRef } from 'react';
import { Elements, Node } from 'react-flow-renderer';

import { ActionNode } from './ActionNode';
// Should be a seperate styles file for overlay elements
import styles from './builder.module.scss';
import { ConditionNode } from './ConditionNode';
import { MessageNode } from './MessageNode';

export const AddNode = (props: any) => {
    const { style, createElement, reactFlowInstance, reactFlowWrapper } = props;
    const menuRef = useRef<HTMLDivElement>(null);

    const handleCreateNode = (event: MouseEvent<HTMLButtonElement> | undefined) => {
        const getElement = (type: string | undefined) => {
            let labelElement: ReactElement;
            switch (type) {
                // This should be refactored to support custom node types.
                // Instead of label, we have to pass data object for each of the type
                case 'message':
                    labelElement = <MessageNode data={{ title: 'New Message', id: '001' }} />;
                    return labelElement;
                case 'action':
                    labelElement = <ActionNode />;
                    return labelElement;
                case 'condition':
                    labelElement = <ConditionNode />;
                    return labelElement;
                default:
                    return 'node not found';
            }
        };

        if (menuRef.current) {
            menuRef.current.style.left = '-1000px';
        }

        event!.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const position = reactFlowInstance!.project({
            x: event!.clientX - reactFlowBounds.left,
            y: event!.clientY - reactFlowBounds.top,
        });

        const randomId = Math.random().toString(36).substring(7);

        const newNode: Node = {
            // Before generating a new node ID, we should check in the current rfInstance
            // if generated ID does not collide with existing node id's
            id: randomId,
            position,
            data: { label: getElement(event?.currentTarget.dataset.type) },
        };

        createElement((es: Elements<any>) => es.concat(newNode));
    };

    return (
        <div className={styles.addNode} style={style} ref={menuRef}>
            <ul>
                <li>
                    <button type="button" data-type="message" onClick={handleCreateNode}>
                        Send Message
                    </button>
                </li>
                <li>
                    <button type="button" data-type="action" onClick={handleCreateNode}>
                        Action
                    </button>
                </li>
                <li>
                    <button type="button" data-type="condition" onClick={handleCreateNode}>
                        Condition
                    </button>
                </li>
            </ul>
        </div>
    );
};
