import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M19.7556 18.5781L14.0682 12.8906C15.1699 11.5298 15.8332 9.80064 15.8332 7.91733C15.8332 3.55238 12.2815 0.000732422 7.91656 0.000732422C3.55161 0.000732422 0 3.55234 0 7.9173C0 12.2823 3.55165 15.8339 7.9166 15.8339C9.7999 15.8339 11.5291 15.1706 12.8899 14.0689L18.5773 19.7564C18.7398 19.9189 18.9531 20.0005 19.1665 20.0005C19.3798 20.0005 19.5932 19.9189 19.7557 19.7564C20.0815 19.4305 20.0815 18.9039 19.7556 18.5781ZM7.9166 14.1672C4.46996 14.1672 1.66666 11.3639 1.66666 7.9173C1.66666 4.47065 4.46996 1.66736 7.9166 1.66736C11.3632 1.66736 14.1665 4.47065 14.1665 7.9173C14.1665 11.3639 11.3632 14.1672 7.9166 14.1672Z"
                fill="#588CBD"
            />
        </svg>
    );
}

export default SvgComponent;
