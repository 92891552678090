import React, { ReactChild } from 'react';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const DEV_MODE = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const DEMO_MODE = 'chatbotas.lt';

export const DataProvider = ({ children }: { children: ReactChild }) => {
    const wsLink = new WebSocketLink({
        // uri: DEV_MODE ? `ws://${window.location.hostname}/subscriptions` : 'wss://req.wtf/subscriptions',
        uri: 'wss://' + DEMO_MODE + '/demo-subscriptions',
        options: {
            reconnect: DEV_MODE ? false : true,
        },
    });

    const httpLink = new HttpLink({
        // uri: DEV_MODE ? `http://${window.location.hostname}/graphql` : 'https://req.wtf/graphql',
        uri: 'https://' + DEMO_MODE + '/demo-graphql',
        credentials: 'include',
    });

    const link = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        httpLink
    );

    const client = new ApolloClient({
        link,
        cache: new InMemoryCache(),
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
