import React, { useState } from 'react';
import style from './billing.module.scss';
import { Stripe } from './Stripe';
import '../../../../styles/utils/_colors.scss';

export const Billing = () => {
    const [isCheckout, setIsCheckout] = useState(false);

    const toggleCheckout = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsCheckout(currentState => !currentState);
    };

    return (
        <div className={style.billingContent}>
            <h1 className={style.title}>Billing</h1>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.
            </p>
            <div className={style.statusContent}>
                <span className={style.status}></span>
                <p>Billing not connected</p>
            </div>
            <br />
            <button onClick={toggleCheckout}>Connect</button>
            {isCheckout && (
                <div className={style.addNewModal}>
                    <Stripe />
                    <span onClick={toggleCheckout} className={style.closeModal}>
                        +
                    </span>
                </div>
            )}
        </div>
    );
};
