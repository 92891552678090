import './flows.css';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FolderIcon from '../../assets/flows/FolderIcon';
import flowsData from '../../mockup-data/flows.json';
import SearchIcon from "../../assets/settings/integrations/search.svg";

interface Flow {
    parent: string;
    id: string;
    name: string;
    deleted: boolean;
}

interface Folder {
    child: Array<string>;
    parent?: string;
    name: string;
    isRoot: boolean;
    key: string;
}

type FlowsType = Array<Flow>;
type FoldersType = Array<Folder>;

export const Flows = () => {
    const [folders, setFolders] = useState<FoldersType>([]);
    const [flows, setFlows] = useState<FlowsType>([]);
    const [currentFolder, setCurrentFolder] = useState<string>('');

    const handleFolderChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        // console.log(e.target.value);
        const { value } = event.target as HTMLInputElement;
        setCurrentFolder(value);
    };

    const history = useHistory();

    function handleClick() {
        history.push('/builder');
    }

    useEffect(() => {
        setFolders(flowsData.folders);
        setFlows(flowsData.flows);
        setCurrentFolder('root');
    }, []);

    return (
        <>
            <div className='FlowHeader'>
                <h2>Chatbot builder</h2>
                    <span className='SearchBox'>
                        <form className='searchForm'>
                        <input type="text" placeholder='Search..'></input>
                        <button type='submit'>
                            <img
                                src={SearchIcon}
                                alt="Meta Messenger Logo"
                                style={{ width: "36px", height: "36px", marginRight: "10px" }}
                            />
                        </button>
                    </form>
                    <a href="#">How to use tables?</a>
                    </span>
            </div>
            <div className="foldersContainer">
                <div className="folderDiv">
                    <FolderIcon />
                    <span className="folderTitle">Chatbot</span>
                </div>
                <div className="folderDiv">
                    <FolderIcon />
                    <span className="folderTitle">Chatbot</span>
                </div>
                <div className="folderDiv">
                    <FolderIcon />
                    <span className="folderTitle">Chatbot</span>
                </div>
                <div className="folderDiv">
                    <FolderIcon />
                    <span className="folderTitle">Chatbot</span>
                </div>
                <div className="folderDiv">
                    <FolderIcon />
                    <span className="folderTitle">Chatbot</span>
                </div>
                <div className="addNewFolder">
                    <span>+</span>
                </div>
            </div>
            <div className="flows-container">
                {folders ? (
                    <div className="folder-container">
                        {folders
                            .filter(
                                (folder: Folder) => folder.key === currentFolder || folder.child.includes(currentFolder)
                            )
                            .map((folder: Folder) => (
                                <>
                                    <button
                                        type="button"
                                        className="folder-item folder-path folder-button"
                                        key={folder.name}
                                        value={folder.key}
                                        onClick={handleFolderChange}
                                    >
                                        {folder.name}
                                    </button>
                                </>
                            ))}

                        {folders
                            .filter((folder: Folder) => folder.parent === currentFolder)
                            .map((folder: Folder) => (
                                <button
                                    type="button"
                                    className="folder-item"
                                    key={folder.name}
                                    value={folder.key}
                                    onClick={handleFolderChange}
                                >
                                    {folder.name}
                                </button>
                            ))}
                    </div>
                ) : (
                    <h1>Loading flows data...</h1>
                )}

                <div className="flow-container">
                    {/* <div className="foldersHeaderRow2">
                        <span>Name</span>
                        <span>Runs</span>
                        <span>Date Created</span>
                        <span>Date Modified</span>
                    </div> */}
                    <table>
                        <thead>
                            <tr>
                                {/*<th style={{ width: '10%' }}>Nr.</th>*/}
                                <th style={{ width: '50%' }}><h2>Name</h2></th>
                                <th style={{ width: '12%' }}>Runs</th>
                                <th style={{ width: '12%' }}>Date created</th>
                                <th style={{ width: '12%' }}>Last modified</th>
                            </tr>
                        </thead>
                        
                    {currentFolder ? (
                        flows
                            .filter((flow: Flow) => flow.parent === currentFolder)
                            .map((flow: Flow) => (
                            <tbody>
                            <tr className="flowItems">
                                
                                <td>
                                <button
                                    type="button"
                                    className="flow-item flow-item-text"
                                    key={flow.id}
                                    value={flow.name}
                                    onClick={handleClick}
                                >
                                    {flow.name}
                                </button>
                                </td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                            </tr>
                            </tbody>
                            ))
                    ) : (
                        <h1>Select flows folder above</h1>
                    )}
                    </table>
                    

                    <button className="addNewWidget">+</button>

                </div>
            </div>
        </>
    );
};
