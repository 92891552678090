import * as React from 'react';

function SvgComponent() {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.1875 8.4375H11.1836C10.4569 8.4375 9.81339 7.97402 9.58389 7.28325L9.48491 6.98514C9.10127 5.83538 8.02913 5.0625 6.81639 5.0625H2.81248C1.26225 5.0625 0 6.32475 0 7.87498V21.375C0 22.9264 1.26225 24.1875 2.81248 24.1875H24.1875C25.7389 24.1875 26.9999 22.9263 26.9999 21.375V11.25C27 9.69975 25.7389 8.4375 24.1875 8.4375ZM25.875 21.375C25.875 22.3054 25.1179 23.0625 24.1875 23.0625H2.81248C1.88209 23.0625 1.12498 22.3054 1.12498 21.375V7.87498C1.12498 6.94459 1.88209 6.18748 2.81248 6.18748H6.81634C7.54307 6.18748 8.18659 6.65096 8.41609 7.34062L8.51507 7.63989C8.89871 8.78966 9.97081 9.56254 11.1836 9.56254H24.1875C25.1179 9.56254 25.875 10.3196 25.875 11.25V21.375H25.875Z"
                fill="#393E85"
                fillOpacity="0.48"
            />
            <path
                d="M23.0624 2.8125H3.93748C2.38725 2.8125 1.125 4.07475 1.125 5.62498C1.125 5.93548 1.37702 6.1875 1.68752 6.1875C1.99802 6.1875 2.25004 5.93548 2.25004 5.62498C2.25004 4.69459 3.00714 3.93748 3.93754 3.93748H23.0626C23.9929 3.93748 24.7501 4.69459 24.7501 5.62498V8.99998C24.7501 9.31048 25.0021 9.56249 25.3126 9.56249C25.6231 9.56249 25.8751 9.31048 25.8751 8.99998V5.62498C25.875 4.07475 24.6138 2.8125 23.0624 2.8125Z"
                fill="#393E85"
                fillOpacity="0.48"
            />
        </svg>
    );
}

export default SvgComponent;
