import { RefObject } from 'react';

export const calculateTranslateTarget = (
    elementRef: HTMLButtonElement | HTMLInputElement,
    widgetToggleRef: RefObject<HTMLButtonElement>,
    isClosing: boolean
) => {
    if (!isClosing) {
        const x = elementRef;
        const qrCoord = x!.getBoundingClientRect();
        const position = {
            top: qrCoord.top + window.pageYOffset,
            left: qrCoord.left + window.pageXOffset,
        };

        // Grabbing coordinates for Close button and using it as translate to coordinates
        const y = widgetToggleRef.current;
        const bottomCoord = y!.getBoundingClientRect();
        const positionTarget = {
            top: bottomCoord.top + window.pageYOffset,
            left: bottomCoord.left + window.pageXOffset,
        };

        const diffCoord = {
            top: positionTarget.top - position.top - 30,
            left: positionTarget.left - position.left - 150,
        };

        elementRef.style.webkitTransform = `translate(${diffCoord.left}px, ${diffCoord.top}px)`;
        elementRef.style.transform = `translate(${diffCoord.left}px, ${diffCoord.top}px)`;
    } else {
        elementRef.style.webkitTransform = `translate(0px, 0px)`;
        elementRef.style.transform = `translate(0px, 0px)`;
    }
};
