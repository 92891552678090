import React, { useState } from 'react';
import style from './tags.module.scss';

const mockedTags = ['Facebook', 'Viber', 'Subscription', 'Lite', 'Needs assistance', 'Started registration'];

const Tag = ({ tagName }: { tagName: string }) => {
    return (
        <div className={style.tag}>
            <span>{tagName}</span>
            <span> +</span>
        </div>
    );
};

export const Tags = () => {
    const [isAddNewActive, setIsAddNewActive] = useState(false);

    const handleAddNewTag = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsAddNewActive(currentState => !currentState);
    };

    return (
        <div className={style.tagsContent}>
            <h1 className={style.title}>Tags</h1>
            {mockedTags.map((tag, i) => {
                return (
                    <React.Fragment key={i}>
                        <Tag tagName={tag} />
                    </React.Fragment>
                );
            })}
            <span className={style.addTag} onClick={handleAddNewTag}>
                + Add new
            </span>
            {isAddNewActive && (
                <div className={style.addNewModal}>
                    Adding new Tag.<button onClick={handleAddNewTag}>Close</button>
                </div>
            )}
        </div>
    );
};
