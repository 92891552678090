import React, { useState } from "react";
import styles from "./settings.module.scss";
import { SettingsNavigation } from "./SettingsNavigation";

import { Account } from "./modules/account/Account";
import { Billing } from "./modules/billing/Billing";
import { ChatbotSettings } from "./modules/chatbot-settings/ChatbotSettings";
import { Integrations } from "./modules/integrations/Integrations";
import { Snippets } from "./modules/snippets/Snippets";
import { Tags } from "./modules/tags/Tags";
import { Team } from "./modules/team/Team";
import { WebWidgets } from "./modules/web-widgets/WebWidgets";

const ModuleSwitch = ({ moduleName }: { moduleName: string }) => {
  switch (moduleName) {
    case "account":
      return <Account />;
    case "billing":
      return <Billing />;
    case "chatbot-settings":
      return <ChatbotSettings />;
    case "integrations":
      return <Integrations />;
    case "snippets":
      return <Snippets />;
    case "tags":
      return <Tags />;
    case "team":
      return <Team />;
    case "web-widgets":
      return <WebWidgets />;
    default:
      return <h1>Select the Tab</h1>;
  }
};

export const Settings = () => {
  const [currentTab, setCurrentTab] = useState("integrations");

  const handleTabChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentTab(
      (event.target as HTMLInputElement).innerText
        .replace(" ", "-")
        .toLowerCase()
    );
  };

  return (
    <div className={styles.settingsContainer}>
      <div className={styles.settingsBody}>
        <div className={styles.settingsLayout}>
          <SettingsNavigation
            tabChangeHandler={handleTabChange}
            activeTabName={currentTab}
          />
          <div className={styles.settingsContent}>
            <ModuleSwitch moduleName={currentTab} />
          </div>
        </div>
      </div>
    </div>
  );
};
