import React, { useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import styles from './widget.module.scss';

export const MessageForm = () => {
    const [messageRequest, setMessageRequest] = useState('');

    const SEND_MESSAGE = gql`
        mutation CreateMessage($messageRequest: String!) {
            createMessage(
                input: {
                    conversation_id: "cb904825-23e1-4300-a642-e4ec8bc8ea4c"
                    type: "request"
                    text: $messageRequest
                    sent_by: "user"
                }
            ) {
                text
                type
            }
        }
    `;

    const SEND_MESSAGE_TO_CONTROLLER = gql`
        mutation converse($message: String!) {
            converse(message: $message) {
                conversation_id
                text
                type
                sent_by
            }
        }
    `;

    const [sendMessage] = useMutation(SEND_MESSAGE_TO_CONTROLLER, {
        ignoreResults: true,
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Submitting Form.');
        sendMessage({ variables: { message: messageRequest } })
            .then(data => {
                console.log(data);
                setMessageRequest('');
            })
            .catch(err => console.log(err));
        console.log('Submitted Form.');
    };

    return (
        <form className={styles.widgetForm} onSubmit={e => handleSubmit(e)}>
            <input
                type="text"
                className={styles.widgetControlsInput}
                placeholder="Type something..."
                required
                name="message"
                value={messageRequest}
                autoComplete="off"
                onChange={e => {
                    setMessageRequest(e.target.value);
                }}
            />

            <button type="submit" className={styles.widgetControlsSend}>
                SEND
            </button>
        </form>
    );
};
