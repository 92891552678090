import React, { useState } from 'react';

import styles from '../conversation.module.scss';
import { TagModal } from '../modals/Tag';

type ConversationHeaderProps = {
    currentConversation: string | boolean | undefined;
};

export const ConversationHeader = ({ currentConversation }: ConversationHeaderProps): JSX.Element => {
    const [isTagModalOpen, setIsTagModalOpen] = useState<boolean>(false);
    // const memoizedSetIsTagModalOpen = useCallback(() => setIsTagModalOpen(true), [setIsTagModalOpen]);

    return (
        <div className={`${styles.conversationHeader}`}>
            <div style={{ display: 'flex' }}>
                <span>Website User {currentConversation}</span>
                <div className={`${styles.activeConversationCircle}`} />
            </div>
            <div className={`${styles.currentConversationActions}`}>
                <span
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => setIsTagModalOpen(c => !c)}
                    onClick={() => setIsTagModalOpen(c => !c)}
                >
                    Tag
                </span>
                <span>Assign</span>
                <span>Custom fields</span>
                <div className={`${styles.menuDots}`}>
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            {isTagModalOpen && (
                <TagModal currentConversation={currentConversation} setIsTagModalOpen={setIsTagModalOpen} />
            )}
        </div>
    );
};
