import React from 'react';
import style from './snippets.module.scss';

const EditIcon = () => {
    return (
        <div className={style.editMenu}>
            <span />
            <span />
            <span />
        </div>
    );
};

export const Snippets = () => {
    return (
        <div className={style.snippetsContent}>
            <h1 className={style.title}>Snippets</h1>

            <div>
                <table>
                    <thead>
                        <tr>
                            {/*<th style={{ width: '10%' }}>Nr.</th>*/}
                            <th style={{ width: '50%' }}>Message</th>
                            <th style={{ width: '30%' }}>Category</th>
                            <th style={{ width: '10%' }} className={style.moreButton}>
                                <span>+</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {/*<th>1</th> */}
                            <th>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </th>
                            <th>
                                <input list="category" name="browser" id="browser" placeholder="category" />
                                <datalist id="category">
                                    <option value="Welcome message" />
                                    <option value="Question" />
                                    <option value="Question 1" />
                                </datalist>
                            </th>
                            <th>
                                <EditIcon />
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
