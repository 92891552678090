import gql from 'graphql-tag';

import { useSubscription } from '@apollo/client';

export const subscription = gql`
    subscription messageCreated {
        messageCreated {
            conversation_id
            text
            type
        }
    }
`;

export default () => useSubscription(subscription);
