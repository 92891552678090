import React from 'react';
import TimeAgo from 'react-timeago';

import styles from '../conversation.module.scss';

interface Conversation {
    userId: string;
    conversationId?: string;
    lastMessageTime?: string;
    lastMessageText?: string;
}

type ConversationsTabsProps = {
    conversations: Conversation[] | null;
    filteredConversations: Conversation[] | null;
    currentConversationID: string | boolean | undefined;
    setCurrentConversation: (value: React.SetStateAction<string | boolean | undefined>) => void;
};

export const ConversationTabs = ({
    conversations,
    filteredConversations,
    currentConversationID,
    setCurrentConversation,
}: ConversationsTabsProps): JSX.Element => {
    const handleConversationClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!(event.target instanceof HTMLButtonElement)) return;
        setCurrentConversation(event.target.dataset.index);
    };

    return (
        <div className={`${styles.conversationSelectorsContainer}`}>
            {conversations ? (
                (
                    filteredConversations ??
                    conversations.sort((a, b) => {
                        const dateA = a.lastMessageTime || '0';
                        const dateB = b.lastMessageTime || '0';
                        return parseInt(dateB, 10) - parseInt(dateA, 10);
                    })
                ).map(convo => (
                    <button
                        type="button"
                        key={convo.userId}
                        data-index={convo.conversationId}
                        className={`${styles.conversationSelectorTab} ${
                            currentConversationID === convo.userId ? styles.activeTab : ''
                        }`}
                        onClick={handleConversationClick}
                    >
                        {`Website User ${convo.userId} - `}
                        <span className={`${styles.tabSubText}`}>
                            <TimeAgo
                                date={
                                    convo.lastMessageTime
                                        ? new Date(parseInt(convo.lastMessageTime, 10))
                                        : new Date(1609105824)
                                }
                            />
                            <br />
                            <br />
                            {convo.lastMessageText ? `${convo?.lastMessageText.slice(0, 40)}...` : '...'}
                        </span>
                        <div className={styles.unreadConversationCircle} />
                    </button>
                ))
            ) : (
                <>
                    <div className={styles.loadingSkeletor} />
                    <div className={styles.loadingSkeletor} />
                    <div className={styles.loadingSkeletor} />
                </>
            )}
        </div>
    );
};
