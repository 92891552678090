import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.832 10.017L19.62 2.23a1.294 1.294 0 000-1.832 1.294 1.294 0 00-1.832 0L10 8.185 2.212.397A1.294 1.294 0 10.38 2.229l7.788 7.788L.38 17.805a1.294 1.294 0 00.916 2.212c.332 0 .664-.127.916-.38L10 11.85l7.788 7.788a1.292 1.292 0 001.832 0 1.294 1.294 0 000-1.832l-7.788-7.788z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgComponent;
