import './index.css';

// Fonts imports
// Should not include not used fonts when rendering a Widget
// Widget should be moved to Preact altogether in later stages
import '@fontsource/poppins';
import '@fontsource/roboto';
import '@fontsource/montserrat';
import '@fontsource/mukta';

import './styles/base/_reset.scss';
// import "./styles/base/_typography.scss";
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
// import EmbedabbleApp from './EmbeddableApp';

// import reportWebVitals from './reportWebVitals';

// const myWindow = window as any;

// Regular Build for a full React Application
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// Widget Build via Parcel into a single .js and .css file

// When rendering Widget Only

// As a HTML element passing data as HTML attributes

// Embedding into HTML:
// <link href="https://req.wtf/widget/index.css" rel="stylesheet" />
// <script src="https://req.wtf/widget/index.js"></script>
// <div id="chat_widget" data-widget-client="CLIENT_ID"></div>
//
//

const widgetDiv = document.getElementById('chat_widget');

// To Do:
//  ✅ 1. Make a different App component with correct prop and data retrieval from HTML data attributes
//  ❌ 2. Use data from HTML data attributes inside Widget Component
//  ❌   2.1. In order to use Client ID for the Widget, back-end Session Query must be adapted to accept Client ID
//  ❌
//  ❌ 3. Upload Parcel Compiled bundle file to http://req.wtf/widget/ directory
//  ❌ 4. Embed HTML Code into WordPress as shown in index.tsx file

// Widget Build
// ReactDOM.render(
//     <React.StrictMode>
//         <EmbedabbleApp domElement={widgetDiv} />
//     </React.StrictMode>,
//     widgetDiv
// );

// As an object with message communications between page and the widget
// myWindow.chatWidget = (idElement: string) => {
//     ReactDOM.render(<App />, document.getElementById(idElement));
//     return this;
// };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
