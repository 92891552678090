import React from 'react';

import { gql, useQuery } from '@apollo/client';
import styles from '../conversation.module.scss';

type ConversationProps = {
    currentConversationID: string | boolean;
};

const MESSAGES_QUERY = gql`
    query conversationMessages($currentConversationID: String!) {
        conversationMessages(conversation_id: $currentConversationID) {
            text
            type
            sent_by
        }
    }
`;

export const ConversationMessages = ({ currentConversationID }: ConversationProps): JSX.Element => {
    interface IMessage {
        sent_by: string;
        text: string;
        type: string;
    }

    const { data, loading, error } = useQuery(MESSAGES_QUERY, {
        variables: { currentConversationID },
        pollInterval: 500,
    });

    if (loading) {
        return <div>Loading messages...</div>;
    }

    if (data) {
        return (
            <div className={styles.messagesContainer}>
                {data.conversationMessages?.length > 0 ? (
                    data.conversationMessages.map(({ text, type }: IMessage, idx: number) => (
                        <div
                            className={`${styles.messageBody} ${
                                type === 'request' ? styles.messageRequest : styles.messageResponse
                            }`}
                            key={idx}
                        >
                            {text}
                        </div>
                    ))
                ) : (
                    <h1>No messages yet.</h1>
                )}
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.messagesContainer}>
                Error Occured while fetching messages. Please reload the application and try again.
            </div>
        );
    }

    return <div className={styles.messagesContainer}>Retrieving messages...</div>;
};
