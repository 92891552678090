import * as React from 'react';

interface MinimizeIconProps extends React.SVGProps<SVGSVGElement> {
    marginLeft: string;
}

function SvgComponent(props: MinimizeIconProps) {
    const { marginLeft } = props;

    return (
        <div
            style={{
                background: '#353886',
                height: '35px',
                width: '35px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                marginLeft,
            }}
        >
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <g clipPath="url(#clip0)">
                    <path
                        d="M17.7803 0.219665C17.4874 -0.0732217 17.0125 -0.0732217 16.7197 0.219665L11.25 5.68934V2.25001C11.25 1.8358 10.9142 1.50002 10.5 1.50002C10.0858 1.50002 9.75 1.8358 9.75 2.25001V7.5C9.75 7.50024 9.75003 7.50052 9.75003 7.50077C9.75007 7.5252 9.75134 7.54964 9.75373 7.574C9.75482 7.58518 9.75692 7.59604 9.75851 7.60705C9.76041 7.62023 9.76195 7.63341 9.76452 7.64649C9.76702 7.65911 9.7705 7.67138 9.77362 7.68379C9.77647 7.69511 9.77893 7.70647 9.78234 7.71768C9.78603 7.72985 9.79057 7.74159 9.79486 7.75347C9.79887 7.76465 9.80256 7.77594 9.80713 7.78698C9.81173 7.79809 9.81711 7.8087 9.82225 7.81953C9.82759 7.83089 9.83265 7.84235 9.83859 7.85349C9.84415 7.86386 9.85048 7.87371 9.85649 7.88376C9.86303 7.89469 9.86918 7.90577 9.87632 7.91642C9.88373 7.92753 9.89203 7.93797 9.90001 7.94866C9.90669 7.95755 9.91284 7.96669 9.91998 7.97534C9.95134 8.01356 9.98639 8.04864 10.0246 8.08C10.0333 8.0871 10.0424 8.09329 10.0513 8.09997C10.062 8.10799 10.0725 8.11625 10.0836 8.12367C10.0942 8.1308 10.1053 8.13699 10.1162 8.14349C10.1263 8.14951 10.1361 8.15584 10.1465 8.16139C10.1576 8.16733 10.1691 8.17239 10.1805 8.17774C10.1913 8.18283 10.2019 8.18825 10.213 8.19285C10.224 8.19742 10.2353 8.20112 10.2465 8.20512C10.2584 8.20941 10.2701 8.21395 10.2823 8.21764C10.2935 8.22101 10.3049 8.22351 10.3162 8.22636C10.3286 8.22949 10.3408 8.23297 10.3535 8.23546C10.3666 8.23807 10.3798 8.23961 10.3929 8.24148C10.404 8.24306 10.4148 8.24513 10.426 8.24626C10.4503 8.24865 10.4748 8.24995 10.4992 8.24995C10.4995 8.24995 10.4997 8.24998 10.5 8.24998H15.75C16.1642 8.24998 16.5 7.91421 16.5 7.5C16.5 7.08578 16.1642 6.75001 15.75 6.75001H12.3106L17.7803 1.28033C18.0732 0.987442 18.0732 0.512587 17.7803 0.219665Z"
                        fill="white"
                    />
                    <path
                        d="M8.24626 10.426C8.24517 10.4148 8.24306 10.4039 8.24148 10.3929C8.23958 10.3798 8.23803 10.3666 8.23547 10.3535C8.23297 10.3409 8.22949 10.3286 8.22636 10.3162C8.22351 10.3049 8.22105 10.2935 8.21764 10.2823C8.21395 10.2701 8.20941 10.2584 8.20512 10.2465C8.20112 10.2353 8.19743 10.224 8.19286 10.213C8.18825 10.2019 8.18287 10.1913 8.17774 10.1805C8.17239 10.1691 8.16733 10.1576 8.16139 10.1465C8.15584 10.1361 8.14951 10.1263 8.1435 10.1162C8.13696 10.1053 8.1308 10.0942 8.12367 10.0836C8.11625 10.0725 8.10795 10.062 8.09997 10.0513C8.09329 10.0424 8.08714 10.0333 8.08 10.0246C8.04864 9.98643 8.01359 9.95134 7.97534 9.91998C7.9667 9.91288 7.95755 9.90669 7.94866 9.90001C7.93797 9.892 7.92753 9.88373 7.91642 9.87632C7.90577 9.86918 7.89469 9.86299 7.88376 9.85649C7.87371 9.85048 7.86386 9.84415 7.85349 9.83859C7.84235 9.83265 7.83089 9.82759 7.81953 9.82225C7.8087 9.81715 7.79805 9.81173 7.78698 9.80713C7.77594 9.80256 7.76465 9.79887 7.75347 9.79486C7.74159 9.79057 7.72985 9.78603 7.71768 9.78234C7.7065 9.77897 7.69511 9.77647 7.68379 9.77362C7.67138 9.7705 7.65915 9.76702 7.64649 9.76452C7.63341 9.76192 7.6202 9.76037 7.60705 9.75851C7.59601 9.75692 7.58518 9.75485 7.574 9.75373C7.54939 9.7513 7.52471 9.75 7.5 9.75H2.25001C1.8358 9.75 1.50002 10.0858 1.50002 10.5C1.50002 10.9142 1.8358 11.25 2.25001 11.25H5.68934L0.219665 16.7197C-0.0732217 17.0125 -0.0732217 17.4874 0.219665 17.7803C0.512552 18.0732 0.987442 18.0732 1.28033 17.7803L6.75001 12.3106V15.75C6.75001 16.1642 7.08578 16.5 7.5 16.5C7.91421 16.5 8.24998 16.1642 8.24998 15.75V10.5C8.25002 10.4753 8.24868 10.4506 8.24626 10.426Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="18" height="18" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default SvgComponent;
