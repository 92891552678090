import { RefObject } from 'react';

import styles from '../../components/widgets/widget.module.scss';
import { calculateTranslateTarget } from './calculateTranslateTarget';

export const animatedHandleChatToggle = (
    widgetRef: RefObject<HTMLDivElement>,
    pageFlipRef: RefObject<HTMLDivElement>,
    quickReplyRefsArray: RefObject<HTMLButtonElement[] | null>,
    widgetToggleRef: RefObject<HTMLButtonElement>
) => {
    const mainClass = widgetRef?.current?.className.split(' ');
    const pageFlipClass = pageFlipRef?.current?.className.split(' ');

    if (widgetRef?.current?.className.includes('fadeOut')) {
        widgetRef.current.className = `${mainClass?.[0]} ${styles.fadeIn}`;
        pageFlipRef.current!.className = `${pageFlipClass?.[0]} ${styles.fadeOut}`;
        quickReplyRefsArray.current?.forEach(element => {
            if (!element) return;
            const qrClass = element.className.split(' ');
            element.className = qrClass?.[0] ?? '';
            calculateTranslateTarget(element, widgetToggleRef, true);
        });
    } else {
        widgetRef.current!.className = `${mainClass?.[0]} ${styles.fadeOut}`;
        pageFlipRef.current!.className = `${pageFlipClass?.[0]} ${styles.fadeIn}`;

        quickReplyRefsArray.current?.forEach(element => {
            if (!element) return;
            const qrClass = element.className.split(' ');
            element.className = `${qrClass?.[0]} ${styles.transformTranslateQuickReplies}`;
            calculateTranslateTarget(element, widgetToggleRef, false);
        });
    }
};
