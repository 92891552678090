import { gql, useQuery } from '@apollo/client';

const TAGS_QUERY = gql`
    query Tags($currentConversation: String!, $clientUUID: String!) {
        tags(conversation_id: $currentConversation, client_uuid: $clientUUID) {
            id
            conversation_id
            tag_name
        }
    }
`;

const UNASSIGN_TAG_MUTATION = gql`
    query {
        unassignTag(conversation_id: $c_id, tag_id: $tag_id) {
            id
            conversation_id
            tag_name
        }
    }
`;

export const useGetTags = (currentConversation: string | boolean | undefined) => {
    const { data, loading, error } = useQuery(TAGS_QUERY, {
        variables: { currentConversation, clientUUID: 'd480ce28-46ea-43e0-b85a-a99ecbd8d674' },
    });
    return { data, loading, error };
};

export const useUnassignTag = (currentConversation: string, tagId: string) => {
    const { data, loading, error } = useQuery(UNASSIGN_TAG_MUTATION, {
        variables: { c_id: currentConversation, tag_id: tagId },
    });
    return { data, loading, error };
};
