import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// import { ManageConversation } from './components/conversations/ManageConversations';
import { Conversations } from './components/conversations/Conversations';
import { Builder } from './components/flow-builder/Builder';
import { Flows } from './components/flows/Flows';
import { Header } from './components/header/Header.component';
import { Widget } from './components/widgets/Widget';
import { Settings } from './components/settings/Settings';
import { Overview } from './components/overview/Overview';
import styles from './styles/_body.module.scss';
import { DataProvider as ApolloProvider } from './utils/ApolloProvider';

function App() {
    return (
        <div className={`${styles.appBody}`}>
            <ApolloProvider>
                <Router>
                    <Header />
                    <Route exact path="/" component={Overview} />
                    <Route exact path="/conversations" component={Conversations} />
                    <Route exact path="/builder" component={Builder} />
                    <Route exact path="/flows" component={Flows} />
                    <Route exact path="/settings" component={Settings} />
                    <Route exact path="/widget" component={Widget} />
                </Router>
            </ApolloProvider>
            <div className={`${styles.bodyVector1}`} />
            <div className={`${styles.bodyVector2}`} />
            <div className={`${styles.bodyVector3}`} />
            <div className={`${styles.bodyVector4}`} />
        </div>
    );
}

export default App;
