import * as React from 'react';

const SvgComponent = ({ fill = '#7E7E7E', ...props }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0)">
                <path
                    d="M8 0.208496C3.58887 0.208496 0 3.67347 0 7.93231C0 12.1911 3.58887 15.6561 8 15.6561C12.4111 15.6561 16 12.1911 16 7.93231C16 3.67347 12.4111 0.208496 8 0.208496ZM8 15.0125C3.95638 15.0125 0.666656 11.8363 0.666656 7.93231C0.666656 4.02828 3.95638 0.852137 8 0.852137C12.0436 0.852137 15.3333 4.02828 15.3333 7.93231C15.3333 11.8363 12.0436 15.0125 8 15.0125Z"
                    fill={fill}
                />
                <path
                    d="M12.2933 9.23322C12.1126 9.19143 11.9342 9.2989 11.8919 9.47175C11.4622 11.2182 9.86197 12.438 7.99997 12.438C6.13572 12.438 4.53481 11.2163 4.10706 9.46768C4.06441 9.29419 3.88375 9.1889 3.70603 9.22818C3.52666 9.26903 3.41566 9.44251 3.45797 9.61536C3.95731 11.6563 5.82516 13.0816 7.99997 13.0816C10.1722 13.0816 12.0393 11.6585 12.5403 9.62071C12.583 9.44755 12.4723 9.27407 12.2933 9.23322Z"
                    fill={fill}
                />
                <path
                    d="M5.3334 6.64495C5.70159 6.64495 6.00006 6.35678 6.00006 6.00131C6.00006 5.64583 5.70159 5.35767 5.3334 5.35767C4.96522 5.35767 4.66675 5.64583 4.66675 6.00131C4.66675 6.35678 4.96522 6.64495 5.3334 6.64495Z"
                    fill={fill}
                />
                <path
                    d="M10.6667 6.64495C11.0348 6.64495 11.3333 6.35678 11.3333 6.00131C11.3333 5.64583 11.0348 5.35767 10.6667 5.35767C10.2985 5.35767 10 5.64583 10 6.00131C10 6.35678 10.2985 6.64495 10.6667 6.64495Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="15.4476" fill="white" transform="translate(0 0.208496)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SvgComponent;
