import Picker, { IEmojiData } from 'emoji-picker-react';
import React, { useEffect, useRef, useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import EmojiIcon from '../../assets/conversation/Emoji';
import FlowIcon from '../../assets/conversation/FlowSelector';
import ImageIcon from '../../assets/conversation/ImageSelector';
import MediaIcon from '../../assets/conversation/MediaSelector';
import SendIcon from '../../assets/conversation/Send';
import styles from './conversation.module.scss';

type MessageFormProps = {
    conversationID: string | boolean;
};

export const MessageForm = ({ conversationID }: MessageFormProps): JSX.Element => {
    const [messageRequest, setMessageRequest] = useState('');

    const emojiPickerNode = useRef<HTMLDivElement>(null);
    const emojiIconRef = useRef<HTMLDivElement>(null);

    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);

    const SEND_MESSAGE = gql`
        mutation CreateMessage($messageRequest: String!, $conversationID: String!) {
            createMessage(
                input: { conversation_id: $conversationID, type: "response", text: $messageRequest, sent_by: "admin" }
            ) {
                text
                type
            }
        }
    `;

    const [sendMessage] = useMutation(SEND_MESSAGE, {
        ignoreResults: true,
    });

    const onEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, emojiObject: IEmojiData) => {
        setMessageRequest(val => val + emojiObject.emoji);
    };

    const handleClickOutsideEmojiModal = (e: MouseEvent) => {
        // Second Ref is incorrect - we should forward Emoji Icon Ref with React.forwardRef()
        if (emojiPickerNode.current?.contains(e.target as Node) || emojiIconRef.current?.contains(e.target as Node)) {
            return;
        }
        setIsEmojiPickerOpen(v => !v);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        sendMessage({ variables: { messageRequest, conversationID } })
            .then(() => {
                setMessageRequest('');
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        if (isEmojiPickerOpen) {
            document.addEventListener('mousedown', handleClickOutsideEmojiModal);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideEmojiModal);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideEmojiModal);
        };
    }, [isEmojiPickerOpen]);

    return (
        <form className={styles.widgetForm} onSubmit={e => handleSubmit(e)}>
            <div className={styles.responseInputContainer}>
                <input
                    className={styles.responseInput}
                    type="text"
                    placeholder="Type something..."
                    required
                    name="message"
                    value={messageRequest}
                    autoComplete="off"
                    onChange={e => {
                        setMessageRequest(e.target.value);
                    }}
                />
                <div className={styles.actionIcons} ref={emojiIconRef}>
                    <EmojiIcon
                        onClick={() => setIsEmojiPickerOpen(c => !c)}
                        fill={isEmojiPickerOpen ? '#353886' : undefined}
                    />
                    <MediaIcon />
                    <FlowIcon />
                    <ImageIcon />
                </div>
            </div>
            {isEmojiPickerOpen && (
                <div className={styles.emojiPickerContainer} ref={emojiPickerNode}>
                    <React.Suspense fallback={<></>}>
                        <Picker
                            onEmojiClick={onEmojiClick}
                            pickerStyle={{ width: '270px', position: 'absolute', top: '-335px', left: '20px' }}
                            disableSkinTonePicker
                        />
                    </React.Suspense>
                </div>
            )}
            <button className={styles.responseMessageFormButtton} type="submit">
                <SendIcon />
            </button>
        </form>
    );
};
