import React from 'react';

import styles from '../conversation.module.scss';

type SearchFilterTogglerProps = {
    isConversationSelectorOpen: boolean;
    isSearchMessages: boolean;
    setIsSearchMessages: (value: React.SetStateAction<boolean>) => void;
};

export const SearchFilterToggler = ({
    isConversationSelectorOpen,
    isSearchMessages,
    setIsSearchMessages,
}: SearchFilterTogglerProps): JSX.Element => {
    return (
        <div className={`${styles.searchFilterToggle}`} style={!isConversationSelectorOpen ? { display: 'none' } : {}}>
            <button
                type="button"
                onClick={() => setIsSearchMessages(false)}
                style={{ fontWeight: isSearchMessages ? 400 : 700 }}
            >
                Contacts
            </button>
            <span> / </span>
            <button
                type="button"
                onClick={() => setIsSearchMessages(true)}
                style={{ fontWeight: isSearchMessages ? 700 : 400 }}
            >
                Messages
            </button>
        </div>
    );
};
