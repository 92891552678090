import React from 'react';
import styles from './intentstable.module.scss';

export const IntentsTable = () => {
    return (
        <div className={styles.intentsTableBody}>
            <div className={styles.header}>
                <h2 className={styles.headerTitle}>Topics and tendencies</h2>
                <h2 className={styles.topicsSelector}>All topics</h2>
            </div>
            <Table />
        </div>
    );
};

const Table = () => {
    return (
        <table className={styles.intentsTable}>
            <TableHeader />

            <TableRows />
        </table>
    );
};

const TableHeader = () => {
    return (
        <thead>
            <tr className={styles.intentRow}>
                <th className={styles.intentHeader}>Keyword</th>
                <th className={styles.intentHeader}>Messages</th>
                <th className={styles.intentHeader}>Date created</th>
                <th className={styles.intentHeader}>Last modified</th>
            </tr>
        </thead>
    );
};

const TableRows = () => {
    return (
        <tbody>
            <tr className={styles.intentRow}>
                <td className={styles.intentData}>Date</td>
                <td className={styles.intentData}>120</td>
                <td className={styles.intentData}>2021-01-20</td>
                <td className={styles.intentData}>2021-03-20</td>
            </tr>
            <tr className={styles.intentRow}>
                <td className={styles.intentData}>Work</td>
                <td className={styles.intentData}>45</td>
                <td className={styles.intentData}>2021-01-20</td>
                <td className={styles.intentData}>2021-03-20</td>
            </tr>
            <tr className={styles.intentRow}>
                <td className={styles.intentData}>Doctor availability</td>
                <td className={styles.intentData}>15</td>
                <td className={styles.intentData}>2021-01-20</td>
                <td className={styles.intentData}>2021-03-20</td>
            </tr>
            <tr className={styles.intentRow}>
                <td className={styles.intentData}>Doctors search</td>
                <td className={styles.intentData}>8</td>
                <td className={styles.intentData}>2021-01-20</td>
                <td className={styles.intentData}>2021-03-20</td>
            </tr>
        </tbody>
    );
};
