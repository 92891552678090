import React from 'react'; // we need this to make JSX compile

import Avatar from '../../assets/widget/Avatar';
import Logo from '../../assets/widget/Logo';
import styles from './widget.module.scss';

export type MessageProps = {
    type: string;
    text: string;
};

export const Message = ({ type, text }: MessageProps) => {
    if (type === 'request') {
        return (
            <div className={`${styles.widgetMsgWrapper} ${styles.msgWrapperRight}`}>
                <div className={`${styles.widgetMessageBody} ${styles.requestMsg}`}>
                    <div className={styles.text}>{text}</div>
                </div>
                <div className={styles.avatar}>
                    <Avatar />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.widgetMsgWrapper}>
            <div className={styles.logoContainerBlack}>
                <Logo />
            </div>
            <div className={`${styles.widgetMessageBody} ${styles.responseMsg}`}>
                <div className={styles.widgetUsername}>Bot</div>
                <div className={styles.text}>{text}</div>
            </div>
            <div className={styles.widgetQuickRepliesContainer}>
                <button
                    type="button"
                    className={styles.widgetQuickReply}
                    onClick={() => console.log('Clicked the QR.')}
                >
                    Lorem ipsum
                </button>
                <button type="button" className={styles.widgetQuickReply}>
                    Lorem ipsum
                </button>
            </div>
        </div>
    );
};
