import './conversations.css';
import React, { useEffect, useRef, useState } from 'react';

import useConversationCreated from '../../hooks/conversations/useConversationCreated';
import useConversationsUpdated from '../../hooks/conversations/useConversationsUpdated';
import { useGetConversations } from '../../hooks/conversations/useGetConversations';
import { ConversationControls } from './controls/Controls';
import { ConversationTabs } from './controls/Tabs';
import styles from './conversation.module.scss';
import { MessageForm } from './MessageForm';
import { ConversationMessages } from './messages/Conversation';
import { ConversationHeader } from './messages/ConversationHeader';

interface Conversation {
    userId: string;
    conversationId?: string;
    lastMessageTime?: string;
    lastMessageText?: string;
}

interface ConversationFromDB {
    client_id: string;
    conversation_status?: string;
    current_flow?: string;
    current_flow_step?: number;
    id: number;
    is_connected?: boolean;
    last_message_time?: string;
    last_text?: string;
    mod_time?: string;
    uuid: string;
}

export const Conversations = () => {
    const [conversations, setConversations] = useState<Conversation[] | null>(null);
    const [filteredConversations, setFilteredConversations] = useState<Conversation[] | null>(null);
    const [currentConversation, setCurrentConversation] = useState<string | boolean | undefined>(false);

    // Conversation Tabs drawer state
    const [isConversationSelectorOpen, setIsConversationSelectorOpen] = useState<boolean>(true);

    const contentElementRef = useRef<HTMLDivElement>(null);

    const fetchedConversations = useGetConversations();
    const updatedConversations = useConversationsUpdated();
    const createdConversations = useConversationCreated();

    useEffect(() => {
        if (createdConversations.data?.conversationCreated) {
            const newConversation: Conversation = {
                userId: createdConversations.data.conversationCreated.id,
                conversationId: createdConversations.data.conversationCreated.uuid,
                lastMessageText: 'Started a conversation',
                lastMessageTime: createdConversations.data.conversationCreated.mod_time,
            };

            setConversations((prevConversations: Conversation[] | null) => {
                return [...(prevConversations ?? []), newConversation];
            });
        }
    }, [createdConversations.data]);

    useEffect(() => {
        if (fetchedConversations.data) {
            setConversations(
                fetchedConversations.data?.conversations
                    .filter((c: ConversationFromDB) => {
                        return c.last_text !== null;
                    })
                    .map((c: ConversationFromDB) => {
                        return {
                            userId: c.id,
                            conversationId: c.uuid,
                            lastMessageText: c.last_text,
                            lastMessageTime: c.last_message_time,
                        };
                    })
            );
        }
    }, [fetchedConversations.data]);

    useEffect(() => {
        if (updatedConversations.data?.messageCreated) {
            setConversations((previousConversations: any) => {
                const updatedConversationsState = previousConversations?.map((c: Conversation) =>
                    c.conversationId === updatedConversations.data?.messageCreated.conversation_id
                        ? {
                              ...c,
                              lastMessageText: updatedConversations.data.messageCreated.text,
                              lastMessageTime: Date.now().toString(),
                          }
                        : c
                );

                return updatedConversationsState;
            });
        }
    }, [updatedConversations.data]);

    return (
        <div
            className={`${styles.conversationBody}`}
            style={!isConversationSelectorOpen ? { gridTemplateColumns: 'minmax(50px, 5%) 1fr' } : {}}
        >
            <div
                className={`${styles.conversationControls}`}
                style={!isConversationSelectorOpen ? { borderRadius: '0px 15px 15px 0px' } : {}}
            >
                <ConversationControls
                    isConversationSelectorOpen={isConversationSelectorOpen}
                    setIsConversationSelectorOpen={setIsConversationSelectorOpen}
                    setFilteredConversations={setFilteredConversations}
                    conversations={conversations}
                />
                {isConversationSelectorOpen && (
                    <ConversationTabs
                        conversations={conversations}
                        filteredConversations={filteredConversations}
                        currentConversationID={currentConversation}
                        setCurrentConversation={setCurrentConversation}
                    />
                )}
            </div>

            {currentConversation ? (
                <div className={`${styles.conversationContentContainer}`} ref={contentElementRef}>
                    <ConversationHeader currentConversation={currentConversation} />
                    <ConversationMessages currentConversationID={currentConversation} />
                    <div className="conversation-controls">
                        <MessageForm conversationID={currentConversation} />
                    </div>
                </div>
            ) : (
                <h1 className="conversation-ui-msg">Select the conversation from left side panel.</h1>
            )}
        </div>
    );
};
