import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={16} height={16} viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.372 14.18c-.032-2.074-.678-3.96-1.827-5.326C9.403 7.497 7.855 6.75 6.186 6.75s-3.217.747-4.359 2.104C.68 10.218.034 12.102 0 14.173c.649.325 3.335 1.577 6.186 1.577 3.084 0 5.585-1.245 6.186-1.57zM6.186 6.188a3.094 3.094 0 100-6.188 3.094 3.094 0 000 6.188z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgComponent;
