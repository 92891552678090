import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M12.1382 7.56094L4.73778 0.180933C4.49531 -0.0607219 4.10275 -0.0603156 3.86069 0.182183C3.61881 0.424651 3.61944 0.817431 3.86194 1.05927L10.8219 8.00003L3.86169 14.9408C3.61922 15.1826 3.61859 15.5752 3.86044 15.8177C3.98178 15.9392 4.14075 16 4.29972 16C4.45828 16 4.61662 15.9396 4.73774 15.8189L12.1382 8.43909C12.255 8.3229 12.3206 8.16478 12.3206 8.00003C12.3206 7.83528 12.2548 7.67734 12.1382 7.56094Z"
                    fill="#588CBD"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgComponent;
