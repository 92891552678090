import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={25} height={26} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0.622767 12.073L24.1682 0.673684C24.3355 0.589065 24.5277 0.734125 24.4967 0.915451L20.0033 25.1405C19.9723 25.3218 19.7368 25.3823 19.6128 25.2433L11.754 16.3583L0.647558 12.478C0.461624 12.4115 0.449229 12.1576 0.622767 12.073Z"
                stroke="white"
                strokeMiterlimit="10"
            />
            <path d="M24.3791 0.668213L11.7542 16.3589" stroke="white" strokeMiterlimit="10" />
        </svg>
    );
}

export default SvgComponent;
