import React from 'react';

export const ActionNode = () => {
    return (
        <div className="actionNode">
            <h3 className="actionHeader">Action Block Example</h3>
            <h5>Add action</h5>
        </div>
    );
};
