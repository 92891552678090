import React, { useCallback, useEffect, useRef, useState } from 'react';

import { gql, useSubscription } from '@apollo/client';
import Avatar from '../../assets/widget/Avatar';
import BarIcon from '../../assets/widget/Bar';
import CloseIcon from '../../assets/widget/Close';
import Logo from '../../assets/widget/Logo';
import MinimizeIcon from '../../assets/widget/Minimize';
import PageFlipImage from '../../assets/widget/PageFlip';
import RotateIcon from '../../assets/widget/Rotate';
import { animatedHandleChatToggle } from '../../utils/animations/animatedChatToggle';
import { Message, MessageProps } from './Message';
import { MessageForm } from './MessageForm';
import styles from './widget.module.scss';

export interface WidgetProps {
    clientId: string | null | undefined;
    welcomeMessage: string | null | undefined;
}

export const Widget = ({ clientId, welcomeMessage }: WidgetProps) => {
    const widgetRef = useRef<HTMLDivElement>(null);
    const pageFlipRef = useRef<HTMLDivElement>(null);
    const contentElementRef = useRef<HTMLDivElement>(null);
    const widgetToggleRef = useRef<HTMLButtonElement>(null);

    const [messagesList, setMessagesList] = useState([] as any);

    const quickReplyRefsArray = useRef<HTMLButtonElement[] | null>([]);

    const addQuickReplyToRefs = useCallback((node: HTMLButtonElement) => {
        if (node !== null) {
            quickReplyRefsArray.current?.push(node);
        }
    }, []);

    // Listening for Incoming Messages
    // To Do: add specific conversation ID to listen for

    const { loading, error, data } = useSubscription(
        gql`
            subscription messageAdded {
                messageAdded {
                    text
                    type
                }
            }
        `
    );

    useEffect(() => {
        if (data?.messageAdded) {
            setMessagesList((previousMessages: MessageProps[]) => [...previousMessages, data.messageAdded]);
        }
    }, [data]);

    useEffect(() => {
        if (messagesList.length > 0) {
            const messageSound = new Audio('/sound/notification_sound.mp3');
            messageSound.play();
        }

        quickReplyRefsArray.current?.forEach(element => {
            // Not working - have to re-do rendering logic
            // Only last response message can have Quick Replies, so before modifying Messages List we
            // should check if the last message had quick replies - if so, remove that data from message data
            // element?.remove();
        });

        contentElementRef?.current?.scrollTo({ top: contentElementRef?.current.scrollHeight, behavior: 'smooth' });
    }, [messagesList]);

    if (loading) {
        // Use this for loading indicator later?
        console.warn('Loading Messages...');
    }

    if (error) {
        // Notify user if he can't receive new messages for some reason
        console.error(error);
    }

    console.log(`Initialized Client Widget with ID: ${clientId}`);
    console.log(`Initialized Client Widget with Welcome Message: ${welcomeMessage}`);

    return (
        <>
            <div className={styles.chatWidgetContainer} ref={widgetRef}>
                <div className={styles.chatWidgetHeader}>
                    <div className={styles.headerItemGroup}>
                        <div className={styles.chatMenu}>
                            <BarIcon />
                            <BarIcon />
                            <BarIcon />
                        </div>
                        <div className={styles.logoContainer}>
                            <Logo />
                        </div>
                        <div className={styles.chatHeaderText}>
                            <div>Chatbot</div>
                        </div>
                    </div>
                    <div className={styles.headerItemGroup}>
                        <div className={styles.headerControls}>
                            <RotateIcon />
                            <MinimizeIcon />
                        </div>
                    </div>
                </div>
                <div className={styles.chatWidgetContent} ref={contentElementRef}>
                    <div className={styles.pageFlipImage} ref={pageFlipRef}>
                        <PageFlipImage />
                    </div>
                    <div className={`${styles.widgetMsgWrapper} ${styles.msgWrapperRight}`}>
                        <div className={`${styles.widgetMessageBody} ${styles.requestMsg}`}>
                            <div className={styles.text}>Lorem ipsum dolor sit amet</div>
                        </div>
                        <div className={styles.avatar}>
                            <Avatar />
                        </div>
                    </div>
                    <div className={styles.widgetMsgWrapper}>
                        <div className={styles.logoContainerBlack}>
                            <Logo />
                        </div>
                        <div className={`${styles.widgetMessageBody} ${styles.responseMsg}`}>
                            <div className={styles.widgetUsername}>Bot</div>
                            <div className={styles.text}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.
                            </div>
                        </div>
                        <div className={styles.widgetQuickRepliesContainer}>
                            <button
                                type="button"
                                className={styles.widgetQuickReply}
                                ref={addQuickReplyToRefs}
                                onClick={() => console.log('Clicked the QR.')}
                            >
                                Lorem ipsum
                            </button>
                            <button type="button" className={styles.widgetQuickReply} ref={addQuickReplyToRefs}>
                                Lorem ipsum
                            </button>
                        </div>
                    </div>
                    <div className={`${styles.widgetMsgWrapper} ${styles.msgWrapperRight}`}>
                        <div className={`${styles.widgetMessageBody} ${styles.requestMsg}`}>
                            <div className={styles.widgetUsername}>Me</div>
                            <div className={styles.textTyping}>...</div>
                        </div>
                        <div className={styles.avatar}>
                            <Avatar />
                        </div>
                    </div>
                    {messagesList.length > 0 &&
                        messagesList.map((m: MessageProps, idx: number) => {
                            return <Message type={m.type} text={m.text} key={idx} />;
                        })}
                </div>
                <div className={styles.chatWidgetFooter}>
                    <MessageForm />
                    <p>Powered by CM</p>
                </div>
            </div>
            <button
                type="button"
                className={styles.widgetToggler}
                onClick={() => animatedHandleChatToggle(widgetRef, pageFlipRef, quickReplyRefsArray, widgetToggleRef)}
                ref={widgetToggleRef}
            >
                <CloseIcon />
            </button>
        </>
    );
};
