import { gql, useQuery } from '@apollo/client';

const CONVERSATIONS_QUERY = gql`
    query {
        conversations {
            id
            uuid
            client_id
            is_connected
            current_flow
            current_flow_step
            conversation_status
            mod_time
            last_text
            last_message_time
        }
    }
`;

export const useGetConversations = () => {
    const { data, loading, error } = useQuery(CONVERSATIONS_QUERY);
    return { data, loading, error };
};
