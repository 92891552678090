import React from "react";

import styles from "./settings.module.scss";

export const SettingsNavigation = ({
  tabChangeHandler,
  activeTabName,
}: {
  tabChangeHandler: React.MouseEventHandler<HTMLButtonElement>;
  activeTabName: string;
}) => {
  const moduleList = [
    "Integrations",
    "Chatbot settings",
    "Web widgets",
    "Tags",
    "Team",
    "Account",
    "Billing",
    "Snippets",
  ];

  return (
    <div className={styles.drawerContainer}>
      <ul>
        {moduleList.map((moduleName, i) => {
          return (
            <li key={i}>
              <button
                className={`${styles.settingsTabButton}${
                  moduleName.replace(" ", "-").toLowerCase() === activeTabName
                    ? ` ${styles.activeTab}`
                    : ""
                }`}
                onClick={tabChangeHandler}
              >
                {moduleName}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
