import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <div
            style={{
                display: 'flex',
                marginLeft: '15px',
            }}
        >
            <svg width="8" height="19" viewBox="0 0 8 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M7.271 3.08818L4.53662 0.353809C4.23193 0.0487305 3.73662 0.0487305 3.43193 0.353809L0.697559 3.08818C0.39248 3.39326 0.39248 3.88779 0.697559 4.19287C1.00225 4.49795 1.49756 4.49795 1.80225 4.19287L3.20303 2.79209V18.0936C3.20303 18.5249 3.55303 18.8749 3.98428 18.8749C4.41553 18.8749 4.76553 18.5249 4.76553 18.0936V2.79209L6.16631 4.19287C6.31865 4.3456 6.51865 4.42178 6.71865 4.42178C6.91865 4.42178 7.11865 4.3456 7.271 4.19287C7.57607 3.88779 7.57607 3.39326 7.271 3.08818Z"
                    fill="#353886"
                />
            </svg>
            <svg width="8" height="19" viewBox="0 0 8 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M7.30224 14.807C6.99717 14.502 6.50263 14.502 6.19756 14.807L4.79678 16.2078V0.90625C4.79678 0.475 4.44678 0.125 4.01553 0.125C3.58428 0.125 3.23428 0.475 3.23428 0.90625V16.2078L1.8335 14.807C1.52842 14.502 1.0335 14.502 0.728808 14.807C0.42373 15.1121 0.42373 15.6066 0.728808 15.9117L3.46318 18.6461C3.61592 18.7988 3.81553 18.875 4.01553 18.875C4.21553 18.875 4.41514 18.7988 4.56787 18.6461L7.30224 15.9117C7.60732 15.6066 7.60732 15.1121 7.30224 14.807Z"
                    fill="#353886"
                />
            </svg>
        </div>
    );
}

export default SvgComponent;
