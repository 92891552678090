import React from 'react';

import SearchIcon from '../../../assets/conversation/Search';
import styles from '../conversation.module.scss';

type ConversationSearchProps = {
    isConversationSelectorOpen: boolean;
    isSearchMessages: boolean;
    conversations: Conversation[] | null;
    setFilteredConversations: (value: React.SetStateAction<Conversation[] | null>) => void;
};

interface IMessage {
    sentBy: string;
    text: string;
}

interface Conversation {
    userId: string;
    conversationId?: string;
    lastMessageTime?: string;
    lastMessageText?: string;
    messages?: IMessage[];
}

export const ConversationSearch = ({
    isConversationSelectorOpen,
    isSearchMessages,
    conversations,
    setFilteredConversations,
}: ConversationSearchProps): JSX.Element => {
    return (
        <div className={styles.searchContainer} style={!isConversationSelectorOpen ? { display: 'none' } : {}}>
            <SearchIcon className={`${styles.searchIcon} ${styles.icon}`} />
            <input
                className={styles.conversationSearchInput}
                placeholder="Search"
                onChange={e => {
                    const needle = e.target.value;
                    let fconv: Conversation[] | undefined = [];
                    if (!isSearchMessages) {
                        fconv = conversations?.filter(convo =>
                            convo.userId.toString().toLowerCase().includes(needle.toLowerCase())
                        );
                    } else {
                        fconv = conversations
                            ?.map(c => {
                                return {
                                    ...c,
                                    messages: c.messages?.filter((m: any) =>
                                        m.text.toLowerCase().includes(needle.toLowerCase())
                                    ),
                                };
                            })
                            .filter(c => c?.messages && c?.messages?.length > 0);
                    }
                    setFilteredConversations(fconv ?? null);
                }}
            />
        </div>
    );
};
