import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={84} height={59} viewBox="0 0 84 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0 49V1h82.897a3 3 0 01.603 0h-.603C73.38 1.967 70.43 48.772 57.5 57 44.3 65.4 2.167 41.167 0 49z"
                fill="#728BE3"
            />
        </svg>
    );
}

export default SvgComponent;
