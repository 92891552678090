import React, { FC } from "react";
import { NavLink } from "react-router-dom";

import LogoIcon from "../../assets/header/AvatarLogo";
import DescendingIcon from "../../assets/header/Descending";
import LogoutIcon from "../../assets/header/LogOut";
import medbotoLogo from "../../assets/static/Logo.png";
import styles from "./header.module.scss";

export const Header: FC = () => {
  return (
    <nav className={styles.navigationContainer}>
      <div className={styles.headerLogo}>
        <img
          src={medbotoLogo}
          alt="medboto"
          style={{ width: "146px", height: "33px" }}
        />
      </div>
      <ul>
        <li>
          <NavLink exact to="/">
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to="/conversations"
            className={styles.withNoticeCounter}
          >
            Conversations
          </NavLink>
          <DescendingIcon style={{ marginLeft: "5px" }} />
        </li>
        <li>
          <NavLink exact to="/flows">
            Builder
          </NavLink>
          <DescendingIcon style={{ marginLeft: "5px" }} />
        </li>
        <li>
          <NavLink exact to="/settings">
            Settings
          </NavLink>
          <DescendingIcon style={{ marginLeft: "5px" }} />
        </li>
        <li>
          <NavLink exact to="/widget">
            Widgets
          </NavLink>
        </li>
      </ul>
      <ul>
        {/* <li>Help</li> */}
        <li>
          <div className={styles.accountBubble}>
            <div className={styles.accountAvatarContainer}>
              <LogoIcon />
            </div>
            <span>Demo Chatbot</span>
            <DescendingIcon />
          </div>
        </li>
        <li>
          <div className={styles.logoutIconContainer}>
            <LogoutIcon />
          </div>
        </li>
      </ul>
    </nav>
  );
};
